import React, { Component } from "react";
import { Card, Table } from "react-bootstrap";
import Pagination from "./Pagination";
import $ from "jquery";
import SearchableSelect from "./SearchableSelect";
import {
  getPriceFormat,
  defaultDateFormat,
  timeSince,
} from "../../utils/global";
import "./web.scss";
import "../../styles/style.css";

class FullWidthResponsiveTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollX: false,
      tableWidth: "0px",
      tableId: "table-" + Math.floor(Math.random() * 100),
      searchable: "",
      columns: [],
      freezeColumnsCount: "",
      rightScrolled: false,
      showFloating: "hidden",
      totalHeight: "70vh",
      pagination: {
        currentPage: 1,
        recordPerPage: 20,
        totalRecord: 100,
      },
    };
  }
  componentDidMount() {
    // if(this.props.freezeColumns === '2'){ this.setState({freezeColumnsCount:'freeze2Columns'}); }
    this.setState({ scrollX: false });
    this.setState({ tableWidth: $(".freeze-table thead tr").width() + "px" });
    if (this.props.type === "2") {
      if ($(window).width() > 991) {
        $(".scrollable thead,.scrollable tbody").width(
          $("#stickyTable .table-responsive").width()
        );
      }
    }
  }

  gototop = () => {
    $("html, body").animate({ scrollTop: 0 }, "fast");
  };

  openOptions = (e) => {
    this.closeOptions();
    e.target.nextSibling.classList.remove("hidden");
  };

  handleSwitch = (e) => {};

  staticSearch = (textSearch, data, key) => {
    let keys = key.split("----");
    if (data[keys[0]].label) {
      let str = data[key].label
        .toLowerCase()
        .includes(textSearch.toLowerCase());
      if (str) {
        return "";
      } else {
        return "hidden";
      }
    } else {
      let textToSearch = "";
      if (keys.length > 1) {
        keys.map((v) => {
          if (data[v]) {
            textToSearch += data[v];
          }
          return null;
        });
      } else {
        textToSearch = data[key];
      }
      let str = textToSearch.toLowerCase().includes(textSearch.toLowerCase());
      if (str) {
        return "";
      } else {
        return "hidden";
      }
    }
  };

  no() {
    return;
  }

  checkScroll = (e) => {
    if (this.props.loadMore) {
      if (
        e.target.scrollTop + e.target.clientHeight ===
        e.target.scrollHeight
      ) {
        this.props.loadMore();
      }
    }
    this.setState({ leftScroll: e.target.scrollLeft });
  };
  render() {
    return (
      <>
        <Card
          className={
            this.props.moveBelow === "false"
              ? "tableCard"
              : "moveBelow tableCard"
          }
        >
          <Card.Body
            className="pb-0 remove-responsive-scroll"
            id="scrollBox"
            style={{ padding: "0px" }}
          >
            <div className="gridTableBox" id={this.state.tableId}>
              <Table
                responsive="sm"
                cellSpacing="0"
                id="gvMain"
                className="gridTableFreeze scrolledFromLeft"
                style={{ width: "100%", borderCollapse: "collapse" }}
              >
                <thead>
                  <tr className="GridViewScrollHeader">
                    {this.props.columns.map((obj, i) => {
                      return (
                        <td key={i} className={obj.class + " sorting-table"}>
                          {this.props.sortTypes ? (
                            this.props.sortTypes[obj.key] ? (
                              <>
                              <a
                                href="/"
                                onClick={(e) =>
                                  this.props.sortHandler(
                                    e,
                                    obj.key,
                                    this.props.sortTypes[obj.key]
                                  )
                                }
                                className={
                                  " sort-by "
                                }
                              >
                                {obj.title}
                              </a>
                              {
                                this.props.sortTypes[obj.key] === 'ASC' ?
                                    <img className={this.props.sortTypes[obj.key]+" sort-icon"} src="./images/sort-1.svg" alt="sort asc" />
                                : this.props.sortTypes[obj.key] === 'DESC' ?
                                    <img className={this.props.sortTypes[obj.key]+" sort-icon"} src="./images/sort-2.svg" alt="sort desc" />
                                :
                                    <img className={this.props.sortTypes[obj.key]+" sort-icon"} src="./images/sort.svg" alt="sort" />
                              }
                              </>
                            ) : (
                              obj.title
                            )
                          ) : (
                            obj.title
                          )}
                        </td>
                      );
                    })}
                  </tr>
                </thead>
                <tbody
                  id="scrollBox"
                  className={this.state.scrollX ? "freeze-body-abs" : ""}
                  style={{ position: "unset" }}
                >
                  {this.props.data.length ? (
                    this.props.data.map((obj, i) => {
                      let columns = this.props.columns;
                      return (
                        <tr
                          key={i}
                          data-key={i}
                          className={
                            this.props.searchText
                              ? this.staticSearch(
                                  this.props.searchText,
                                  obj,
                                  this.props.staticSearch
                                ) + " GridViewScrollItem"
                              : "GridViewScrollItem gridRow"
                          }
                        >
                          {columns.map((col, k) => {
                            return col.key === "action" ? (
                              <td
                                key={k}
                                className={col.class + " actionColumnMain"}
                              >
                                {obj[col.key].map((button, j) => {
                                  return (
                                    <span className="actionColumn" key={j}>
                                      <span
                                        key={j}
                                        data-key={button.key}
                                        className={
                                          button.disabled
                                            ? "action-icon disabled-action"
                                            : "action-icon"
                                        }
                                        onClick={() =>
                                          button.disabled
                                            ? console.log("Disabled")
                                            : this.props.handleActions(
                                                button.key,
                                                obj.id
                                              )
                                        }
                                      >
                                        <span className="tooltiptext">
                                          {
                                            button.key === 'view' ?
                                              <img style={{marginRight:'10px'}} src={'./images/edit.svg'} alt="view"/>
                                            :
                                            button.key === 'delete' ?
                                              <img src={'./images/delete.svg'} alt="view"/>
                                            :
                                            button.title
                                          }
                                        </span>
                                      </span>
                                    </span>
                                  );
                                })}
                              </td>
                            ) : col.type === "searchableSelect" ? (
                              <td
                                key={k}
                                data-row={i}
                                data-col={k}
                                className={
                                  col.class +
                                  (this.state.searchable === i
                                    ? " active-search"
                                    : "")
                                }
                              >
                                <div className="">
                                  <SearchableSelect
                                    data_tabindex_1={i}
                                    data_tabindex_2={k}
                                    totalRecords={this.props.data.length}
                                    tabIndex={i + 1}
                                    col={col}
                                    addNewItem={this.props.addNewItem}
                                    handleRowFieldsChange={
                                      this.props.handleRowFieldsChange
                                    }
                                    currentValue={obj[col.key]}
                                    searchable={this.state.searchable}
                                    options={col.options}
                                    i={i}
                                  />
                                  {this.props.tableErrors.length ? (
                                    this.props.tableErrors[i] ? (
                                      this.props.tableErrors[i][col.key] ? (
                                        <span className="error-msg">
                                          {this.props.tableErrors[i][col.key]}
                                        </span>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </td>
                            ) : col.type === "blank" ? (
                              <td
                                key={k}
                                data-row={i}
                                data-col={k}
                                className={col.class}
                              ></td>
                            ) : col.type === "switch" ? (
                              <td
                                key={k}
                                data-row={i}
                                data-col={k}
                                className={col.class}
                              >
                                <label className="switch">
                                  <input
                                    type="checkbox" 
                                    onClick={() =>
                                      col.callBack ? col.callBack(obj) : ""
                                    }
                                    checked={
                                      obj[col.key] === true ? true : false
                                    }
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </td>
                            ) 
                            : col.type === "download" ? (
                              <td>
                                <a onClick={(e)=>this.props.download(e,i)} style={{fontSize:'14px',textDecoration:'none',color:'#656565'}} href="/#"><img style={{marginRight:'10px'}} src="./images/download.svg" alt="download"/>Download</a>
                              </td>
                            ) 
                            :
                            col.type === "status" ? (
                              <td className="text-center">
                                <div className="statusIcons fullWidth">
                                  <div class={obj[col.key] === 'false' ? "icon" : "icon active"}></div>
                                </div>
                              </td>
                            ) 
                            : (
                              <td
                                key={k}
                                className={
                                  col.class +
                                  " " +
                                  this.state.freezeColumnsCount +
                                  " " +
                                  (col.checkbox ? "checkboxContainer" : "")
                                }
                              >
                                {obj[col.key] || obj[col.key] === 0 ? (
                                  col.pre ? (
                                    col.pre + obj[col.key]
                                  ) : col.html ? (
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: obj[col.key],
                                      }}
                                    ></span>
                                  ) : col.price ? (
                                    "$" + getPriceFormat(obj[col.key])
                                  ) : col.percentageLine ? (
                                    <div className="percentageBarContainer">
                                      {obj[col.key]}
                                      <div className="percentageBar">
                                        <div
                                          style={{ width: obj[col.key] + "%" }}
                                        ></div>
                                      </div>
                                    </div>
                                  ) : col.mutiMax ? (
                                    <span>
                                      {obj[col.key].length > col.mutiMax ? (
                                        <span>
                                          {obj[col.key][0]}
                                          <div className="plusNumbers">
                                            <a
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.props.moreItems(
                                                  obj[col.key],
                                                  col
                                                );
                                              }}
                                              href="/#"
                                            >
                                              +
                                              {obj[col.key].length -
                                                col.mutiMax}
                                            </a>
                                          </div>
                                        </span>
                                      ) : obj[col.key][0] ? (
                                        obj[col.key][0]
                                      ) : (
                                        "--"
                                      )}
                                    </span>
                                  )
                                  : col.action ? (
                                    <a style={{color:'#087cca',textDecoration:'none'}} href="/#" onClick={(e)=>{e.preventDefault(); this.props.handleActions('view',obj.id)}}>{obj[col.key]}</a>
                                  ) : col.date ? (
                                    defaultDateFormat(obj[col.key])
                                  ) : col.checkbox ? (
                                    <div className="form-check">
                                      {this.props.selectedRowIds.includes(
                                        obj.id
                                      )}
                                      <input
                                        type="checkbox"
                                        id={
                                          this.props.name
                                            ? this.props.name + "push-" + obj.id
                                            : "push-" + obj.id
                                        }
                                        checked={
                                          this.props.selectedRowIds.includes(
                                            obj.id
                                          )
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          this.props.selectRowHandler(e, obj.id)
                                        }
                                        className="form-check-input"
                                      />
                                      <label
                                        htmlFor={
                                          this.props.name
                                            ? this.props.name + "push-" + obj.id
                                            : "push-" + obj.id
                                        }
                                        className="form-check-label"
                                      >
                                        {obj[col.key]}
                                      </label>
                                    </div>
                                  ) : col.radio ? (
                                    <div
                                      className="form-check"
                                      style={{ marginLeft: "30px" }}
                                    >
                                      {this.props.selectedRowIds.includes(
                                        obj.id
                                      )}
                                      <input
                                        type="radio"
                                        id={
                                          this.props.name
                                            ? this.props.name + "push-" + obj.id
                                            : "push-" + obj.id
                                        }
                                        checked={
                                          this.props.selectedRowIds.includes(
                                            obj.id
                                          )
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          this.props.selectRowHandler(e, obj.id)
                                        }
                                        className="form-check-input tableRadio"
                                      />
                                      <label
                                        htmlFor={
                                          this.props.name
                                            ? this.props.name + "push-" + obj.id
                                            : "push-" + obj.id
                                        }
                                        className="form-check-label"
                                      >
                                        {obj[col.key]}
                                      </label>
                                    </div>
                                  ) : col.timeSince ? (
                                    timeSince(obj[col.key])
                                  ) : (
                                    obj[col.key]
                                  )
                                ) : (
                                  "--"
                                )}
                                {col.otherKey ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: obj[col.otherKey],
                                    }}
                                  ></div>
                                ) : (
                                  ""
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        style={{
                          minWidth: this.state.tableWidth,
                          boxShadow: "none",
                        }}
                        className="noRecordsFound"
                        colSpan="6"
                      >
                        <p className="mb-1">
                          {this.props.noRecordsMsg
                            ? this.props.noRecordsMsg
                            : "No record found in the list"}
                        </p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
        {this.props.data.length && this.props.showPagination ? (
          <Pagination
            length={this.props.data.length}
            pagination={this.props.pagination}
            paginationHandler={this.props.paginationHandler}
            paddingBottom="none"
            tableId={this.state.tableId}
            pagingCount={this.props.pagingCount === false ? false : true}
          ></Pagination>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default FullWidthResponsiveTable;
