import axios from "axios";
import { globalVariable } from "./global";

export const checkLoggedIn = () => {
    if(localStorage.token){
        return true;
    }else{
        return false;
    }
}

export const PostWithoutToken = async (url,data)  => {
    return await axios.post(globalVariable.baseURL + url,data,{
        headers: {
            "Content-Type": "application/json"
        },
    }).then(function (response) {
       return response.data;
    })
    .catch(function (error) {
        if(error?.response?.data){
            return error.response.data
        }else{
            return error
        }
    });
}



export const PostData = async (url,data)  => {
    return await axios.post(globalVariable.baseURL + url,data,{
        headers: {
            "Content-Type": "application/json",
            "access_token": localStorage.token
        },
    }).then(function (response) {
       return response.data;
    })
    .catch(function (error) {
        if(error?.response?.status === 401){
            setTimeout(()=>{ localStorage.clear(); window.location.href="/"; },1000)
        }
        return error.response.data
    });
}

export const GetData = async (url)  => {
    return await axios.get(globalVariable.baseURL + url,{
        headers: {
            "Content-Type": "application/json",
            "access_token": localStorage.token
        },
    }).then(function (response) {
       return response.data;
    })
    .catch(function (error) {
        if(error?.response){
            if(error?.response?.status === 401){
                setTimeout(()=>{ localStorage.clear(); window.location.href="/"; },1000)
            }
            return error?.response?.data
        }else{
            return error
        }
        
    });
}

export const PutData = async (url,formData)  => {
    return await axios.put(globalVariable.baseURL + url,formData,{
        headers: {
            "Content-Type": "application/json",
            "access_token": localStorage.token
        },
    }).then(function (response) {
       return response.data;
    })
    .catch(function (error) {
        if(error?.response){
            if(error?.response?.status === 401){
                setTimeout(()=>{ localStorage.clear(); window.location.href="/"; },1000)
            }
            return error?.response?.data
        }else{
            return error
        }
    });
}

export const DeleteData = async (url,data)  => {
    return await axios.delete(globalVariable.baseURL + url,{
        headers: {
            "Content-Type": "application/json",
            "access_token": localStorage.token
        },
    }).then(function (response) {
       return response.data;
    })
    .catch(function (error) {
        if(error?.response?.status === 401){
            setTimeout(()=>{ localStorage.clear(); window.location.href="/"; },1000)
        }
        return error.response.data
    });
}