import React,{ useState,useRef } from "react";
import { Link } from "react-router-dom";
import { PostWithoutToken } from '../../utils/apiHandler'
import Loader from '../../components/Loader'
import Header from "../Common/Header";
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../../utils/constants';

const ForgetPassword = (props) => {
  const notificationRef = useRef();
  const [loader,showLoader] = useState(false)
  const [userName,setUserName] = useState('')
  const [userNameError,setUserNameError] = useState('')

  const forgetPassword = () => {
    if(userName === ''){
      setUserNameError("Please enter Email ")
    }else{
      setUserNameError("")
      showLoader(true)
      PostWithoutToken('admin/forgot',{email:userName}).then((response)=>{
        showLoader(false)
        
        if(response.status === true){
          notificationRef.current.addNotification({
            message: response.msg,
            level:  'info',
          });
        }else{
            setUserNameError(response.msg)
            notificationRef.current.addNotification({
              message: response.msg,
              level:  'error',
            });
        }
      })
    }
  }

  return (
    <>
      <Header showMenu={false}/>
      <div className="login">
        <Loader show={loader} />
        <div className="login__wrap">
          <h2>Forget Password</h2>
          <div className="login__form">
            <form>
              <div className="input__wrap">
                <p>Email</p>
                <input 
                  type="email" 
                  value={userName} 
                  placeholder="Email" 
                  className="text__box login-input" 
                  onChange={(e)=>{
                    if(e.target.value === ''){
                      setUserNameError("Please enter Email")
                    }else{
                      setUserNameError('')
                    }
                    setUserName(e.target.value)
                  }} />
                <div className="user_span" id="span">
                  {userNameError}
                </div>
              </div>
              <div className="btn__wrap">
                <button type="button" onClick={()=>forgetPassword()} className="submit__btn">Submit</button>
                <Link to="/" className="forget__btn">
                  Back
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <NotificationSystem
            dismissible={false}
            ref={notificationRef}
            style={NOTIFICATION_SYSTEM_STYLE}
          />
    </>
  );
};
export default ForgetPassword;
