import React,{useState,useEffect,useRef} from "react";
import Loader from '../../components/Loader'
import { GetData } from "../../utils/apiHandler";
import {UncontrolledReactSVGPanZoom} from 'react-svg-pan-zoom';

const Part = (props) => {
    const Viewer = useRef(null);
    const diagramId = atob(props.match.params.id)
    const [loader,setLoader] = useState(false)
    const [filterData, setFilterData] = useState([]);
    const [diagramImage,setDiagramImage] = useState('')
    const [coordinates,setCoordinates] = useState([])
    const [tooltip,setTooltip] = useState({left:0,top:0})
    const [tooltipPart,setTooltipPart] = useState({})
    const [showTooltip,setShowTooltip] = useState(false)
    const [list,setList] = useState([])

    const _zoomOnViewerCenter = () => Viewer.current.zoomOnViewerCenter(1.1)
    const _fitSelection = () => Viewer.current.fitSelection(40, 40, 200, 200)
    const _fitToViewer = () => Viewer.current.fitToViewer()

    useEffect(()=>{
       // Viewer.current.fitToViewer();
        setLoader(true)
        GetData('admin/diagrams/'+diagramId+'?page=1&limit=1000').then((response)=>{
            setLoader(false)
            if(response.status === true){
                let sno = 1
                let coordinates = []
                let listData = []
                response.data.part.map((data)=>{
                    data.sno = sno
                    data.rowStatus = data.description && data.specification ? 1 : 0
                    listData.push(data)
                    if(data.coordinates){
                        if(data.coordinates.x && data.coordinates.y){
                            coordinates.push({x:data.coordinates.x,y:data.coordinates.y,part:data})
                        }else{
                            delete data.coordinates
                        }
                    }
                    sno++;
                    return null
                })
                setCoordinates(coordinates)
                setList(listData)
                if(response.data.diagram[0].images){
                    setDiagramImage(response.data.baseUrl+response.data.diagram[0].images[0])
                }
            }
        });
    },[])

    const handlefilter =(event) =>{
        const searchword=event.target.value;
        //console.log('Data',list)
        const newfilter= list.filter((value) =>{
            return value.description.toLowerCase().includes(searchword.toLowerCase());
        });
        if(searchword === ""){
            setFilterData([])
        }
        else{
            setFilterData(newfilter);
        }

    };

    const showPointer = (v) => {
        console.log('showPointer',v)
        if(document.getElementById(v.id)){
            document.getElementById(v.id).scrollIntoView();
            document.getElementById(v.id).click();
        }
    }

    const setColor = (e,k,color) => {
        let coordinatesTemp = [...coordinates]
        coordinatesTemp[k]['color'] = color
        setShowTooltip(true)
        setCoordinates(coordinatesTemp)
        setTooltip({left:e.pageX,top:e.pageY})
        setTooltipPart(coordinatesTemp[k].part)
    }

    return (
        <>
        <Loader show={loader} />
        <h1 className='title'>EXPLODED VIEW AND PARTS LIST</h1>
            <div className='new'>
            <button className="btn" onClick={() => _zoomOnViewerCenter()}>Zoom on center</button>
            <button className="btn" onClick={() => _fitSelection()}>Zoom area 200x200</button>
            <button className="btn" onClick={() => _fitToViewer()}>Fit</button>
                <div className='search-parts'>
                    <label className='search-lable'>Search Parts:</label>
                    <input className='new-input' type='text'onChange={handlefilter} />
                    <button type="submit" disabled id="searchBtn" class="new-search-btn" >Search</button>
                </div>

            </div>
            <div className='new new2' style={{paddingBottom:'100px'}}>
            <div className='new-search-table'>
                {filterData.length !== 0 && (
                <div className='data-result'>
                    {
                        filterData.map((value,key) => {
                          return (
                          <p onClick={()=>showPointer(value)} className='data-items'>{value.description} ({value.partNo})</p>
                        );

                        })
                    }

                </div>
                 )}
                 </div>
                 {
                            diagramImage ? 
                            <div style={{border: '1px solid #f2f2f2',width:'100%',position:'relative'}}>
                                <UncontrolledReactSVGPanZoom
                                    ref={Viewer}
                                    width={500} height={500}
                                    onZoom={e => console.log('zoom')}
                                    onPan={e => console.log('pan')}
                                    onClick={event => console.log('click', event.x, event.y, event.originalEvent)}
                                >
                                <svg style={{zIndex:1}} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 453.3 630.1">
                                    <image x="0" y="0" style={{width:'100%'}} xlinkHref={diagramImage} />
                                    {console.log('coordinates',coordinates)}
                                    {
                                        coordinates.map((c,k)=>{
                                            return(
                                                <g key={k}  className="highlight" style={{fill: c.color ? c.color : "black",cursor:'pointer',zIndex:2}}>
                                                    <circle id={c.part.id} onClick={(e)=>setColor(e,k,'red')} onMouseLeave={(e)=>setColor(e,k,'black')} onMouseEnter={(e)=>setColor(e,k,'red')} cx={c.x} cy={c.y} r="8" className="panzoom-exclude tooltip-dot tooltipstered" data-tooltip-content="#tooltip_content_da97-06137c"></circle>
                                                </g>
                                            )
                                        })
                                    }
                                </svg>
                                </UncontrolledReactSVGPanZoom>
                            </div>
                            :
                            ""
                    }
                
                {/*<TransformWrapper >
                  {  ({zoomIn, zoomOut,resetTransform, ...rest}) => (
                    <>
                        <div className='icons'>
                            <img className='icons-main' src={origin+'/images/zoom-in.png'} onClick={()=>zoomIn()}></img>
                            <img className='icons-main' src={origin+'/images/zoom-out.png'} onClick={()=>zoomOut()}></img>
                            <img className='icons-main' src={origin+'/images/reset.png'} onClick={()=> resetTransform()}></img>
                        </div>
                        
                        <TransformComponent>
                        
                        
                        </TransformComponent>

                            

                    </>
                    )}
                  </TransformWrapper>*/}

            </div>
            <div id="tooltip_content_da67-02035a" style={{display:showTooltip ? 'inline-block' : 'none',position:'absolute',padding:'20px',background: '#fff',left:tooltip.left,top:tooltip.top,border: '2px solid',borderRadius:'2px'}}>
                    <div style={{fontWeight:'bold',marginBottom:'5px'}}>{tooltipPart.partNo}</div>
                    <div style={{marginBottom:'5px'}}>{tooltipPart.specification}</div>
                    <div style={{marginBottom:'5px'}}>$1.85</div>
                    <div style={{marginBottom:'5px'}}>
                        <a href="http://diagrams.samsungparts.com/page.php?modelNumber=RF28K9070SG&amp;baseUrl=http://diagrams.samsungparts.com/diagram_new/RF28K9070SG/RF28K9070SG_2/js/partsList.json&amp;fileName=/var/www/diagrams.samsungparts.com.standalone//diagram_new/RF28K9070SG/RF28K9070SG_2/page.html">Add to Cart</a>
                    </div>
                    <div style={{marginBottom:'5px'}}>
                        <a rel="noreferrer" href="http://diagrams.samsungparts.com/page.php?modelNumber=RF28K9070SG&amp;baseUrl=http://diagrams.samsungparts.com/diagram_new/RF28K9070SG/RF28K9070SG_2/js/partsList.json&amp;fileName=/var/www/diagrams.samsungparts.com.standalone//diagram_new/RF28K9070SG/RF28K9070SG_2/page.html#" target="_blank">View Details</a> 
                    </div>
                </div>
        </>
    )
}

export default Part