import React from "react";

const Loader = (props) => {
    return(
        <div className="loader-container" style={props.show ? {display:'flex',top:'0px',justifyContent:'center',zIndex:'5000',width:'100%',position:'fixed',background:'#1a1a1a66',alignItems:'center',height:'100vh'} : {display:'none'}}>
            <div className="lds-facebook"><div></div><div></div><div></div></div>
        </div>
    )
}

export default Loader;