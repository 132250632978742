import React, { Component } from 'react';
import $ from 'jquery'
class SearchableSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
          scrollX: false,
          searchable:'',
          options:[],
          searchkey:'',
          optionSelected : 0,
          activeBox:'',
          offsetTop:{top:'0px'},
          currentValue: {
              label:'',
              value:''
          }
        };
        this.windowScrollHandler = this.windowScrollHandler.bind(this);
      }
    componentDidMount(){
        this.setState({options:this.props.options,selectedOption:''})
        window.addEventListener("scroll", this.windowScrollHandler);
    }

    windowScrollHandler(event) {
        if($('.searchableBoxOptions'))
        {
            if(window.innerWidth < 600)
            {
                if($('.active-search').length){
                   // let offset = $('.active-search').offset().top - $(window).scrollTop()
                   // this.setState({offsetTop:{top:offset + 40}})
                }
            }
           // console.log('cccc',$('.searchableBoxOptions').position().top+'  '+window.scrollY)
           // let totalOffset = $('.searchableBoxOptions').position().top - window.scrollY;
            //this.setState({offsetTop:{top:totalOffset}})
        }
    
    }

    handleInputFocus  = (e,i) => {
        
        let offset = $(e.target).offset().top+40;
        if($('body').outerHeight(true) <= (offset+200)){
            if(window.innerWidth < 600)
            {
                //offset = offset
                offset = offset - $(window).scrollTop()
            }
            else
            {
                offset = offset - 250;
                offset = offset - $(window).scrollTop()
            }
        }
        
        if(window.innerWidth < 600)
        {
            $(e.target).closest('.table-responsive').css('padding-bottom','100px')
            $(e.target).closest('tbody').css('display','contents')
            $(e.target).closest('.searchableBox').css('position','relative')
            $(e.target).closest('.searchableBox').find('.box').css('position','absolute')
            offset = 40;
        }
        
        this.setState({offsetTop:{top:offset}})
        if(e.target.closest('td')){ e.target.closest('td').classList.add('active-search') }
        this.setState({searchable:'',activeBox:e})
        setTimeout(()=>this.setState({searchable:i}),5);
    }

    handleInputBlur = (e,i) => {
        let currentEvent = e.target
        setTimeout(()=>{
            if(window.innerWidth < 600)
            {
                $('.active-search').closest('.table-responsive').css('padding-bottom','0px')
                $('.active-search').closest('tbody').css('display','block')

                this.setState({searchable:'',activeBox:''})    
                //$('.active-search').removeClass('active-search')
            }
            else{
                this.setState({searchable:'',activeBox:''})    
                if(currentEvent.closest('td')){ currentEvent.closest('td').classList.remove('active-search') }
            }
            
        },500);
    }

    selectItem = (option) => {
        this.props.handleRowFieldsChange(option,this.props.i,this.props.col.key)
        if(window.innerWidth < 600)
        {
            $('.active-search').closest('.table-responsive').css('padding-bottom','0px')
            $('.active-search').closest('tbody').css('display','block')

            this.setState({searchable:'',activeBox:''})    
            $('.active-search').removeClass('active-search')
        }
    }

    filterByValue = (arrayOfObject, term) => {
        var ans = arrayOfObject.filter(function(v,i) {
            if(v.label.toLowerCase().indexOf(term) >=0) {
                return true;
            } else {
                return false;
            }
        });
        this.setState({options:ans})
        //console.log( ans);
    }

    handleKeyPress = (e) => {
        let optionSelected = this.state.optionSelected
        if(e.keyCode === 13){
            this.selectItem(this.state.options[optionSelected])
        }
        if(e.keyCode === 38){
            //up button
            if(optionSelected !== 0)
            {
                let currentOptionSelected = optionSelected - 1;
                this.setState({optionSelected:currentOptionSelected})
            }
        }
        if(e.keyCode === 40){
            //down button
            let options = this.state.options;
            if(options.length >= optionSelected+2){
                let currentOptionSelected = optionSelected + 1;
                this.setState({optionSelected:currentOptionSelected})
            }
        }
        if(e.keyCode === 8){
            this.setState({searchkey:e.target.value})
            this.props.handleRowFieldsChange('',this.props.i,this.props.col.key)
            let options = this.props.options;
            this.filterByValue(options, e.target.value);
        }
        
    }

    selectSearchOption = (e) => {
        this.setState({searchkey:e.target.value})
        let value = e.target.value;
        let options = this.props.options;
        this.setState({optionSelected:0})
        this.filterByValue(options, value);
    }


    render(){
        let i = this.props.i;
        return(
            <div className="searchableBox">
                
                <input type="text" id={'searchable-'+this.props.tabIndex} data_tabindex_1={this.props.data_tabindex_1} data_tabindex_2={this.props.data_tabindex_2} tabIndex={this.props.tabIndex} autoComplete="off" style={{minWidth:'125px'}} className="inputBox width70 border" data-id={this.props.currentValue.value} value={this.props.currentValue.label!==undefined ? this.props.currentValue.label : this.state.searchkey} name="qty-0-0" onKeyUp={(e)=>this.handleKeyPress(e)} onChange={(e)=>this.selectSearchOption(e,i)} onBlur={(e)=>this.handleInputBlur(e,i)} onFocus={(e)=>this.handleInputFocus(e,i)} />
                <div style={this.state.offsetTop} className={this.state.searchable === i ? 'searchableBoxOptions box' : 'hidden box'}>
                    <ul className="scrollable" style={{overflowY:'scroll'}}>
                            {
                                this.state.options.length ?

                                    this.state.options.map((option,s)=>{                                        
                                    return(
                                        option.hidden ? 
                                        '' :
                                        <li key={s} onClick={()=>this.selectItem(option)} className={s===this.state.optionSelected ? "searchableItem selectedOption" : "searchableItem" } >{option.label}</li>
                                    )})
                                :
                                <li className="searchableItem addItem" onClick={this.props.addNewItem}>No Records Found</li>
                            }
                    </ul>
                    <ul>
                        <li className={this.state.options.length < 4 ? "searchableItem seprator absolute" : "searchableItem seprator" }></li>
                        <li className={this.state.options.length < 4 ? "searchableItem addItem absolute" : "searchableItem addItem" } onClick={this.props.addNewItem}>+ ADD NEW ITEM</li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default SearchableSelect