import { USER_LOGIN } from "./loggedInUser"
const initialState = localStorage.userData ? {loggedInUser:JSON.parse(localStorage.userData)} : {}

const loggedInUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
        state.loggedInUser = action.payload
      return state
    
    default:
      return state
  }
}

export default loggedInUserReducer