import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Footer from "./screens/Common/Footer";
import Login from "./screens/Auth/Login";
import Logout from "./screens/Auth/Logout"
import ForgetPassword from "./screens/Auth/ForgetPassword";
import AddBulkData from "./screens/BulkUpload/AddBulkData";
import TableView from "./screens/TableView";
import ResetPassword from "./screens/Auth/ResetPassword";
import Diagram from "./screens/Diagrams/View";
import Part from "./screens/Diagrams/Part";
//import PartDemo from "./screens/Diagrams/PartDemo";
import TableImg from "./screens/Table/TableImg";
import { Provider } from "react-redux";
import store from './redux/store'
import DiagramList from "./screens/Diagrams/List";

const App = () => {
  return (
    <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Login}></Route>
        <Route exact path="/part/:id" component={Part}></Route>
        {/*<Route exact path="/part-demo/:id" component={PartDemo}></Route>*/}
        <Route exact path="/forget-password" component={ForgetPassword}></Route>
        <Route exact path="/bulk-upload" component={AddBulkData}></Route>
        <Route exact path="/table-view" component={TableView}></Route>
        <Route exact path="/reset-password" component={ResetPassword}></Route>
        <Route exact path="/diagram-list" component={DiagramList} ></Route>
        <Route exact path="/diagram-list/view/:id" component={Diagram} ></Route>
        <Route exact path="/table-edit" component={TableImg} ></Route>
        <Route exact path="/logout" component={Logout} ></Route>
        <Route component={Login} />
      </Switch>
      <Footer />
    </Router>
    </Provider>
  );          
  }

export default App;
