import React, { useState,useEffect } from "react";
import Modal from "react-modal";
import { PutData } from '../../utils/apiHandler'

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "50%",
    bottom: "auto",
    marginRight: "-40.5%",
    transform: "translate(-50%, -50%)",
    transition: "all 1s ease-in",
  },
};
Modal.setAppElement("#root");

const EditSeo = (props) => {
  const [seoTitle,setSeoTitle] = useState('')
  const [seoTitleError,setSeoTitleError] = useState('')
  const [seoDescription,setSeoDescription] = useState('')
  const [seoDescriptionError,setSeoDescriptionError] = useState('')
  const closeEditseoModal = () => {
    props.callbackEditSeo(false);
    setSeoTitle('')
    setSeoTitleError('')
    setSeoDescription('')
    setSeoDescriptionError('')
  };

  useEffect(()=>{
    setSeoTitle(props.seoData.seoTitle)
    setSeoDescription(props.seoData.seoDescription)
  },[props])

  const editHandler =(e)=> {
    e.preventDefault();
    let error=0;
    setSeoTitleError('')
    setSeoDescriptionError('')
    
    if(seoTitle===""){
      error=1;
      setSeoTitleError('Please enter SEO Title')
    }
    if(seoDescription===""){
      error=1;
      setSeoDescriptionError('Please enter SEO Description')
    }

    if(!error){
      const formData ={
        seoTitle:seoTitle,
        seoDescription:seoDescription
      }
      props.setLoader(true)
      PutData("admin/diagrams/"+props.diagramId,formData).then((response)=>{
        props.setLoader(false)
        props.editSEOhandler(response)
      })
    }
  }

  return (
    <div className="edit-seo-container">
      <Modal
        isOpen={props.openEditseoModalProp}
        shouldCloseOnOverlayClick={() => props.callbackEditSeo(false)}
        onRequestClose={() => props.callbackEditSeo(false)}
        style={customStyles}
        animation={true}
        animationType="slide"
        backdrop="static"
      >
        <div className="editseo__container">
          <div className="close-icon">
            <img
              src={window.location.origin+"/images/cancel.svg"}
              alt="close"
              onClick={closeEditseoModal}
            />
          </div>

          <div className="editseo__wrap">
            <h2 className="edit-h2">Edit SEO</h2>
            <div className="editseo__form">
              <form>
                <div className="editseo__form__div">
                  <p className='modals-lable'>SEO Title</p>
                  <div className='w-100'>
                  <input type="text"
                   className="text__box"
                   onChange={(e)=>{
                  if(e.target.value===""){
                    setSeoTitleError('Please enter SEO Title')
                  }
                  else{
                    setSeoTitleError('')
                  }
                  setSeoTitle(e.target.value);
                   }} 
                   value={seoTitle}
                   />
                   <br/>
                   <span className="user_span" id="edit_span">
                   {seoTitleError}
                   </span>
                   </div>
                </div>
                <div className="editseo__form__div">
                  <p>SEO Description</p>
                  <div className='w-100'>
                  <input type="text" 
                  className="text__box"
                  onChange={(e)=>{
                    if(e.target.value===''){
                      setSeoDescriptionError('Please enter SEO Description')
                    }
                    else{
                      setSeoDescriptionError('')
                    }
                    setSeoDescription(e.target.value);
                  }}
                  value={seoDescription}
                   />
                   <br/>
                   <span className="user_span" id="edit_span">
                   {seoDescriptionError}
                   </span>
                   </div>
                   
                </div>
                <div className="editseo__form__div editseo__flexrow">
                  <button type="reset" onClick={closeEditseoModal} className="submit__btn bg--dark">
                    CANCEL
                  </button>
                  <button type="submit"
                   className="submit__btn bg--blue"
                   onClick={(e)=> editHandler(e)}>
                    SAVE
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditSeo;
