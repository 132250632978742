import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Header = (props) => {
  const origin = window.location.origin
  const [showMenu, setShowMenu] = useState(false);
  //const [showDropdown, setShowDropdown] = useState(false);
  const [userName,setUserName] = useState('--')
  const reducerData = useSelector(state => state);
  // show dropdown
  /*const showDropdownHandler = (e) => {
    const slideDropdown = !showDropdown;
    setShowDropdown(slideDropdown);
    e.stopPropagation();
  };*/

  // show mobile menu method
  const showMobileMenu = () => {
    const MenuStatus = !showMenu;
    setShowMenu(MenuStatus);
  };
  
  useEffect(()=>{
    if(reducerData?.user?.loggedInUser){
      const loggedInData = reducerData.user.loggedInUser
      setUserName(loggedInData.firstName+" "+loggedInData.lastName)
    }
  },[reducerData])

  return (
    <>
      {/* Desktop Header */}
      <div className="header">
        <div className="header__logo">
          {
            props.showMenu ? 
            <img
              src={origin+'/images/menu.svg'}
              alt="hanberger"
              onClick={showMobileMenu}
              className="hanberger"
            />
          :
          ""
          }
          <img src={origin+'/images/New-logo.svg'} style={{width:'200px'}} alt="logo" />
        </div>
        <div className="header__tabs">
          {
            props.showMenu ?
              <ul className="header__tabls--ul">
                <li className={props.activeMenu === 'bulkData' ? "active" : ""}>
                  <Link to="/bulk-upload">Upload</Link>
                </li>
                <li className={props.activeMenu === 'diagrams' ? "active" : ""}>
                  <Link to="/diagram-list">Diagrams</Link>
                </li>
              </ul>
              :
              ""
          }
        </div>
        {
            props.showMenu ?
          <div className="header__profile--icon">
            <img src={origin+'/images/user.svg'} alt="user" />
            <h2>{userName}</h2>
            <div className="header__profile--slide">
                <Link to="/logout" className='logout-link'> 
                  <img className='logout-img' alt="logout"  src={origin+'/images/logout.svg'}/>
                </Link>
              {/*
              <img
                src={origin+'/images/dropdown.svg'}
                alt="dropdown"
                onClick={showDropdownHandler}
              />
              {showDropdown === true ? (
                <ul>
                  <li className='main-logout-btn'>
                    
                  </li>
                </ul>
              ) : null}

              */}
            </div>
          </div>
        :
        ""
        }
      </div>
      {/* Mobile Header */}
      <div
        className={
          "header-mobile " +
          (showMenu === true
            ? "mobile--animation-plus"
            : "mobile--animation-minus")
        }
      >
        <div className="header-mobile--icon">
          <img
            src={origin+'/images/close.svg'}
            alt="hanberger"
            onClick={showMobileMenu}
            className='cancle-img'
            
          />
        </div>
        <ul className="header-mobile__tabls--ul">
          <li className="active">
            <Link to="/bulk-upload">Upload</Link>
          </li>
          <li>
            <Link to="/diagram-list">Diagrams</Link>
          </li>
          <li>
            <Link to="/logout">Logout</Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Header;
