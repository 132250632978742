import React, { useState,useRef,useEffect } from "react";
import { PostData,checkLoggedIn } from "../../utils/apiHandler";
import Loader from "../../components/Loader";
import Header from "../Common/Header";
import NotificationSystem from "react-notification-system";
import { NOTIFICATION_SYSTEM_STYLE } from "../../utils/constants";

const AddBulkData = (props) => {
  const diagramSample = [{diagramName:"",modelNumber:"",csvData:"",images:"",mapImageName:"",partInfoFileName:""}]
  const notificationRef = useRef();
  const [loader, setLoader] = useState(false);
  const [diagrams,setDiagrams] = useState(diagramSample)
  const [diagramsError,setDiagramsError] = useState([{diagramName:"",modelNumber:"",csvData:"",images:""}])

  useEffect(() => {
    if(!checkLoggedIn()){
      props.history.push('/')
    }
  }, [props]);

  //   add diagrams
  const addMoreDiagram = () => {
    let diagram = {diagramName:"",modelNumber:"",csvData:"",images:"",mapImageName:"",partInfoFileName:""}
    const diagramsTemp = [...diagrams,diagram]
    const diagramsErrorTemp = [...diagramsError,{diagramName:"",modelNumber:"",csvData:"",images:""}]

    setDiagramsError(diagramsErrorTemp)
    setDiagrams(diagramsTemp)
  };

  const onChangeHandler = (key,value,index) => {
    let diagramsTemp = [ ...diagrams ];
    let diagramsErrorTemp = [...diagramsError]
    diagramsErrorTemp[index][key] = ""
    diagramsTemp[index][key] = value
    setDiagrams(diagramsTemp)
    setDiagramsError(diagramsErrorTemp)
  }

  //   delete diagrams
  const deleteDiagram = (value) => {
    let diagramsTemp = [ ...diagrams ];
    let diagramsErrorTemp = [ ...diagramsError ];
    diagramsTemp.splice(value,1)
    diagramsErrorTemp.splice(value,1)
    setDiagrams(diagramsTemp)
    setDiagramsError(diagramsErrorTemp)
  };

  // file upload textbox
  const uploadMapHandler = (event,key,index) => {
    if(event.target.files.length){
      let diagramsTemp = [ ...diagrams ];
      let diagramsErrorTemp = [ ...diagramsError ];
      if(key === 'images') {
        let formData = new FormData()
        formData.append('images',event.target.files[0])
        setLoader(true)
        PostData("admin/diagrams/uploadimages",formData).then((response)=>{
          setLoader(false)
          if(response.status === true){
            diagramsTemp[index]['mapImageName'] = event.target.files[0].name
            diagramsTemp[index][key] = response.data.images
            diagramsErrorTemp[index][key] = ""
            setDiagramsError(diagramsErrorTemp)
            setDiagrams(diagramsTemp)

          }
        })
      }else{
        diagramsTemp[index]['partInfoFileName'] = event.target.files[0].name
        diagramsTemp[index][key] = event.target.files[0]
        diagramsErrorTemp[index][key] = ""
      
        setDiagramsError(diagramsErrorTemp)
        setDiagrams(diagramsTemp)
      }
    }
  };

  const submitForm = () => {
    let formData = new FormData()
    let error = 0
    let diagramsErrorTemp = [...diagramsError]
    let errorIndexes = []
    diagrams.map((data,index)=>{
      if(data.diagramName === ""){
        error = 1
        errorIndexes.push(index)
        diagramsErrorTemp[index].diagramName = 'Please enter diagram name'
      }
      if(data.modelNumber === ""){
        error = 1
        errorIndexes.push(index)
        diagramsErrorTemp[index].modelNumber = 'Please enter model number'
      }
      if(data.csvData === ""){
        error = 1
        errorIndexes.push(index)
        diagramsErrorTemp[index].csvData = 'Please select csv'
      }
      if(data.images === ""){
        error = 1
        errorIndexes.push(index)
        diagramsErrorTemp[index].images = 'Please select images'
      }
      formData.append('diagramName_'+(index+1),data.diagramName)
      formData.append('modelNumber_'+(index+1),data.modelNumber)
      formData.append('csvData_'+(index+1),data.csvData)
      formData.append('images_'+(index+1),data.images)
      return null
    })
    setDiagramsError(diagramsErrorTemp)
    if(!error){
      setLoader(true)
      PostData('admin/diagrams/upload',formData).then((response)=>{
        setLoader(false)
        if(response.status === true){
          notificationRef.current.addNotification({
            message: response.msg,
            level: "info",
          });

          setDiagrams(diagramSample)
          setTimeout(()=>{
            document.getElementById('image-0').value = ""
            document.getElementById('csv-0').value = ""
          },500)
        }else{
          let errorIndexesApi = []
          let diagramsErrorTemp1 = [...diagramsError]
          for(let k in response.data){
            if(response.data[k].errors.key.includes('csvData')){
              errorIndexesApi.push(k-1);
              diagramsErrorTemp1[k-1].csvData = response.data[k].errors.error
            }else{
              diagramsErrorTemp1[k-1].csvData = ""
            }
            if(response.data[k].errors.key.includes('images')){
              errorIndexesApi.push(k-1);
              diagramsErrorTemp1[k-1].images = response.data[k].errors.error
            }else{
              diagramsErrorTemp1[k-1].images = ""
            }
            if(response.data[k].errors.key === ""){
              errorIndexesApi.push(k-1);
              diagramsErrorTemp1[k-1].diagramName = response.data[k].errors.error
            }
          }
          if(errorIndexesApi.length){
            document.getElementById('diagram-'+errorIndexesApi[0]).scrollIntoView({ behavior: 'smooth', block: 'start' })
          }
          setDiagramsError(diagramsErrorTemp1)
          notificationRef.current.addNotification({
            message: response.msg,
            level: "error",
          });
        }
      })
    }else{
      document.getElementById('diagram-'+errorIndexes[0]).scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
    <>
    <Loader show={loader} />
    <Header showMenu={true} activeMenu="bulkData"/>
    <div className="add-bulk">
      <h2>Add New Diagrams</h2>

      <div className="add-bulk__container">
        <form>
          {diagrams.map((a, i) => {
              return (
                i < 10 ?
                  <div key={i} id={'diagram-'+i} className="diagram--loop">
                    <div className="diagram__del--div">
                      <h2>Diagram - {i+1}</h2>
                      {i !== 0 ? (
                        <p onClick={() => deleteDiagram(i)}>Delete</p>
                      ) : null}
                    </div>
                    <div className="add-bulk__diagram">
                      <div className="diagram__row">
                        <div className="input__wrap">
                          <p>Diagram Name</p>
                          <input
                            type="text"
                            placeholder="Enter Diagram Name"
                            className="text__box"
                            onChange={(e)=>onChangeHandler('diagramName',e.target.value,i)}
                            value={a.diagramName}
                          />
                          <span className="error">{diagramsError[i].diagramName}</span>
                        </div>
                        <div className="input__wrap">
                          <p>Model Number</p>
                          <input
                            type="text"
                            placeholder="Enter Model Number"
                            onChange={(e)=>onChangeHandler('modelNumber',e.target.value,i)}
                            className="text__box"
                            value={a.modelNumber}
                          />
                          <span className="error">{diagramsError[i].modelNumber}</span>
                        </div>
                      </div>
                      <div className="diagram__row">
                        <div className="input__wrap">
                          <p>Upload Map Image(.jpg, .png)</p>
                          <div className="custom__file--wrap">
                            <input
                              type="text"
                              placeholder="Upload JPG,PNG File..."
                              className="text__box"
                              id="inputMapImage"
                              value={a.mapImageName}
                              readOnly
                            />
                            <div className="submit__btn custom__file--btn">
                              <label htmlFor={"image-"+i}>Browse</label>
                              <input
                                type="file"
                                id={"image-"+i}
                                accept=".jpg,.JPG,.png,.PNG"
                                onChange={(e)=>uploadMapHandler(e,'images',i)}
                                className="custom--fileupload"
                              />
                            </div>
                          </div>
                          <span className="error">{diagramsError[i].images}</span>
                        </div>
                        <div className="input__wrap">
                          <p>Upload Part Info File (.csv)</p>
                          <div className="custom__file--wrap">
                            <input
                              type="text"
                              placeholder="Upload CSV File..."
                              className="text__box"
                              id="inputCsv"
                              value={a.partInfoFileName}
                              readOnly
                            />
                            <div className="submit__btn custom__file--btn">
                              <label htmlFor={"csv-"+i}>Browse</label>
                              <input
                                type="file"
                                id={"csv-"+i}
                                accept=".csv"
                                onChange={(e)=>uploadMapHandler(e,'csvData',i)}
                                className="custom--fileupload"
                              />
                            </div>
                          </div>
                          <span className="error">{diagramsError[i].csvData}</span>
                        </div>
                      </div>
                    </div>
                    <hr className="horizontal-line" />
                  </div>
                :
                ""
              );
          })}
          <div className="add-more__diagram">
            <div className="add-more__innerwrap">
              {
                diagrams.length === 10 ?
                  <span className="error-msg">Maximum limit reached!</span>
                :
                  <span onClick={addMoreDiagram}>
                    <img src="./images/add.svg" alt="add" /> Add More Diagrams
                  </span>
              }
            </div>
            <button type="button" onClick={()=>submitForm()} className="submit__btn">
              Upload Files
            </button>
          </div>
        </form>
      </div>
    </div>
    <NotificationSystem
        dismissible={false}
        ref={notificationRef}
        style={NOTIFICATION_SYSTEM_STYLE}
      />
    </>
  );
};

export default AddBulkData;
