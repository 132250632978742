import React from "react";
import Modal from "react-modal";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "50%",
    bottom: "auto",
    marginRight: "-40.5%",
    transform: "translate(-50%, -50%)",
    transition: "all 1s ease-in",
  },
};
Modal.setAppElement("#root");

const AssignCoordinateInfo = (props) => {
  // close modal
  const closeHowToAssignModal = () => {
    props.callbackHowToAssign(false);
  };

  return (
    <div className="edit-seo-container">
      <Modal
        isOpen={props.openHowToAssignModalProp}
        onRequestClose={() => props.callbackHowToAssign(false)}
        shouldCloseOnOverlayClick={() => props.callbackHowToAssign(false)}
        style={customStyles}
        animation={true}
        animationType="slide"
        backdrop="static"
      >
        <div className="editseo__container">
          <div className="close-icon">
            <img
              src={window.location.origin+"/images/cancel.svg"}
              alt="close"
              onClick={closeHowToAssignModal}
            />
          </div>

          <div className="editseo__wrap">
            <h2 className='assign_h2'>To assign a coordinate to a part:</h2>
            <div className="editseo__form">
              <form>
                <div className="editseo__form__div">
                  <ol className="assign__coordinate__modal">
                    <li>
                     <b>1.</b> Click on the <strong>ACTIVATE DIAGRAM</strong> button.
                    </li>
                    <li>
                    <b>2.</b> Click once on aspot on the diagram below where you want a
                      link to appear.
                    </li>
                    <li> 
                    <b>3.</b> A popup will appear. Search and select the part that you
                          want to assign to the chosen coordinate.{" "}
                    </li>
                    <li>
                    <b>4.</b>  Click the <strong> DEACTIVATE DIAGRAM</strong> button to
                      save the assignment.
                    </li>
                  </ol>
                </div>
                <div className="editseo__form__div flex__center">
                  <button type="submit" className="submit__btn close-btn">
                    CLOSE
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AssignCoordinateInfo;
