import React, { useEffect } from "react";
import Modal from "react-modal";
import { DeleteData } from "../../utils/apiHandler";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    zIndex:1000
  },
  content: {
    top: "50%",
    left: "50%",
    right: "50%",
    bottom: "auto",
    marginRight: "-40.5%",
    transform: "translate(-50%, -50%)",
    transition: "all 1s ease-in",
  },
};
Modal.setAppElement("#root");
const DeleteDiagram = (props) => {
  useEffect(()=>{
    
  },[props])

  const closeDeleteModal = () => {
    props.callbackDelete(false);
  };

  const deletePart = () => {
    props.setLoader(true)
    console.log('props.currentId',props.currentId)
    DeleteData('admin/diagrams/'+props.currentId,{}).then((response)=>{
      props.setLoader(false)
      console.log('response',response)
      props.getDiagrams()
      if(response.status){
        props.callbackDelete(false,response);
      }else{
        props.callbackDelete(false,response);
      }
    })
  }

  return (
    <div className="edit-seo-container">
      <Modal
        isOpen={props.openDeleteModalProp}
        onRequestClose={() => props.closeDeleteModal(false)}
        shouldCloseOnOverlayClick={() => props.callbackDelete(false)}
        //onRequestClose={() => props.callbackDelete(false)}
        style={customStyles}
        animation={true}
        animationType="slide"
        backdrop="static"
      >
        <div className="editseo__container">
          <div className="close-icon">
            <img
              src={window.location.origin+"/images/cancel.svg"}
              alt="close"
              onClick={closeDeleteModal}
            />
          </div>

          <div className="editseo__wrap"> 
            <p>Are you sure you want to delete this diagram ?</p>
            
            <div className="editseo__form"> 
              <form>
                <div className="editseo__form__div editseo__flexrow">
                  <button type="reset" onClick={closeDeleteModal} className="submit__btn bg--dark">
                    CANCEL
                  </button>
                  <button type="button" onClick={deletePart} className="submit__btn del__btn">
                    DELETE
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteDiagram;
