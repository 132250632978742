import React from "react";
import Modal from "react-modal";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "50%",
    bottom: "auto ",
    marginRight: "-40.5%",
    transform: "translate(-50%, -50%)",
    transition: "all 1s ease-in",
  },
};
Modal.setAppElement("#root");
const UpdatePart = (props) => {
  const closeUpdateModal = () => {
    props.callbackUpdate(false);
  };

  return (
    <div className="edit-seo-container">
      <Modal
        isOpen={props.openUpdateModalProp}
        onRequestClose={() => props.callbackUpdate(false)}
        shouldCloseOnOverlayClick={() => props.callbackUpdate(false)}
        style={customStyles}
        animation={true}
        animationType="slide"
        backdrop="static"
      >
        <div className="editseo__container">
          <div className="close-icon">
            <img
              src="./images/cancel.svg"
              alt="close"
              onClick={closeUpdateModal}
            />
          </div>

          <div className="editseo__wrap">
            <h2>Update a Part</h2>
            <div className="editseo__form">
              <form>
                <div className="editseo__form__div">
                  <p className="font-italic">*All fields are required.</p>
                </div>
                <div className="editseo__form__div">
                  <p>Part Number</p>
                  <input type="text" className="text__box" />
                </div>
                <div className="editseo__form__div">
                  <p>Number</p>
                  <input type="text" className="text__box" />
                </div>
                <div className="editseo__form__div">
                  <p>Description</p>
                  <input type="text" className="text__box" />
                </div>
                <div className="editseo__form__div">
                  <p>Spec</p>
                  <input type="text" className="text__box" />
                </div>

                <div className="editseo__form__div editseo__flexrow">
                  <button type="reset" className="submit__btn bg--dark">
                    CANCEL
                  </button>
                  <button type="submit" className="submit__btn update-save">
                    SAVE
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UpdatePart;
