import React,{ useState,useRef,useEffect } from "react";
import { Link } from "react-router-dom";
import { PostWithoutToken,checkLoggedIn } from '../../utils/apiHandler'
import Loader from '../../components/Loader'
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../../utils/constants';
import { useDispatch } from "react-redux";
import {USER_LOGIN} from '../../redux/loggedInUser'
import Header from "../Common/Header";

const Login = (props) => {
  const notificationRef = useRef();
  const dispatch = useDispatch();
  const [userName,setUserName] = useState('')
  const [userNameError,setUserNameError] = useState('')
  const [password,setPassword] = useState('')
  const [passwordError,setPasswordError] = useState('')
  const [loader,setLoader] = useState(false)
  const setUserLogin = item => dispatch({ type: USER_LOGIN, payload: item })


  useEffect(()=>{
    if(checkLoggedIn()){
      props.history.push('/bulk-upload')
    }
  },[props])

  const loginHandler = () => {
    let error = 0;
    if (userName === "") {
      error = 1;
      setUserNameError("Please enter email");
    }
    if (password === "") {
      error = 1;
      setPasswordError("Please enter password");
    }

    if(!error){
      setPasswordError('')
      setUserNameError('')
      const formData = {
        email:userName,
        password:password
      }
      setLoader(true)
      PostWithoutToken('admin/login',formData).then((response)=>{
        
        if(response.status === false){
          setLoader(false)
          notificationRef.current.addNotification({
            message: response.msg,
            level:  'error',
          });
        }else{
          localStorage.setItem('token',response.token)
          localStorage.setItem('userData',JSON.stringify(response.updatedUser))
          setUserLogin(response.updatedUser)
          setTimeout(()=>props.history.push('/bulk-upload'),1000);
        }
      })
    }
  };

  return (
    <>
    <Header showMenu={false}/>
    <div className="login">
      <Loader show={loader} />
      <div className="login__wrap">
        <h2>Login</h2>
        <div className="login__form">
          <form>
            <div className="input__wrap">
              <p>Email</p>
              <input
                type="text"
                placeholder="Email"
                className="text__box login-input"
                onChange={(e)=>{
                  if(e.target.value === ''){
                    setUserNameError('Please enter email')
                  }else{
                    setUserNameError('');
                  }
                  setUserName(e.target.value);
                }}
                value={userName}
              />
              <br />
              <span className="user_span" id="user_span">
                {userNameError}
              </span>
            </div>
            <div className="input__wrap">
              <p>Password</p>
              <input
                type="password"
                placeholder="*****"
                className="text__box login-input"
                onKeyDown={(e)=>{ if(e.keyCode === 13){ loginHandler() } }}
                onChange={(e)=>{
                  if(e.target.value === ''){
                    setPasswordError('Please enter password')
                  }else{
                    setPasswordError('')
                  }
                  setPassword(e.target.value);
                }}
                value={password}
              />
              <br />
              <span className="user_span" id="span">
                {passwordError}
              </span>
            </div>
            <div className="btn__wrap">
            <button type="button" onClick={()=>loginHandler()} className="submit__btn">Login</button>

              <Link to="/forget-password" className="forget__btn">
                Forget Password
              </Link>
            </div>
          </form>
        </div>
      </div>
      <NotificationSystem
            dismissible={false}
            ref={notificationRef}
            style={NOTIFICATION_SYSTEM_STYLE}
          />
    </div>
    </>
  );
};

export default Login;
