import React, { useState } from "react";
import AddPartNo from "../../components/Modals/AddPartNo";
import AssignCoordinateInfo from "../../components/Modals/AssignCoordinateInfo";
import DeletePart from "../../components/Modals/DeletePart";
import EditSeo from "../../components/Modals/EditSeo";
import UpdatePart from "../../components/Modals/UpdatePart";
import Header from "../Common/Header";
const TableImg = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddPartModal, setShowAddPartModal] = useState(false);
  const [howToAssign, setHowToAssign] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const closeEditModal = (getValue) => {
    setShowEditModal(getValue);
  };

  const closeAddPartModal = (getAddPart) => { 
    setShowAddPartModal(getAddPart);
  };

  const closeHowToAssignModal = (getHowToAssign) => {
    setHowToAssign(getHowToAssign);
  };

  const closeDeleteModal = (getDeleteModal) => {
    setDeleteModal(getDeleteModal);
  };

  const closeUpdateModal = (getUpdateModal) => {
    setUpdateModal(getUpdateModal);
  };

  return (
    <>
    <Header showMenu={true} activeMenu = "diagrams"/>
      {/* Edit seo modal */}
      <EditSeo
        openEditseoModalProp={showEditModal}
        callbackEditSeo={closeEditModal}
      />
      {/* Add part number */}
      <AddPartNo
        openAddPartModalProp={showAddPartModal}
        callbackAddPart={closeAddPartModal}
      />
      {/* How to assign Modal */}
      <AssignCoordinateInfo
        openHowToAssignModalProp={howToAssign}
        callbackHowToAssign={closeHowToAssignModal}
      />

      {/* Delete Modal */}
      <DeletePart
        openDeleteModalProp={deleteModal}
        callbackDelete={closeDeleteModal}
      />

      {/* Update Modal */}
      <UpdatePart
        openUpdateModalProp={updateModal}
        callbackUpdate={closeUpdateModal}
      />

      <div className="upper">
        <div className="back">
          <img alt="sample" src="./images/back.svg"></img> Back
        </div>
        <div className="row">
          <div className="refrigerator">Refrigerator - RS267LABP</div>

          <div className="btn-1">
            <button className="edit-btn" onClick={() => setShowEditModal(true)}>
              EDIT SEO 
            </button>
          </div>
          <div className="btn-1">
            <button
              className="add-btn"
              onClick={() => setShowAddPartModal(true)}
            >
              ADD PART NO.
            </button>
          </div>
        </div>
        <div className="input-main">
          <input
            type="text"
            className="text__box table_input"
            placeholder="Enter Keywords"
          ></input>
          <img alt="sample" className="icon-img" src="./images/search.svg"></img>
        </div>
      </div>
      <div className="table">
        <div className="Part_table">
          <div className="left">
            <table>
              <tr>
                <th>No.</th>
                <th>Part Number</th>
                <th>Description</th>
                <th>Action</th>
                <th>
                  Status
                  <img alt="sample" src="./images/sort.svg"></img>
                </th>
                <th>Specs</th>
              </tr>
              <tr>
                <td>1</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img
                    alt="edit"
                    src="./images/edit.svg"
                    onClick={() => setUpdateModal(true)}
                  ></img>
                  <img
                    alt="delete"
                    src="./images/delete.svg"
                    className="del_img"
                    onClick={() => setDeleteModal(true)}
                  ></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>2</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>3</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>4</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>5</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>6</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>7</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>8</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>9</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>10</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>10</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>10</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>10</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>10</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>10</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>10</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>10</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>10</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>10</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>10</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>10</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>10</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>10</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>10</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>10</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>10</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>10</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
              <tr>
                <td>10</td>
                <td>DA97-08689A</td>
                <td>ASSY COVER EVAP-FRE</td>
                <td>
                  <img alt="sample" src="./images/edit.svg"></img>
                  <img alt="sample" src="./images/delete.svg" className="del_img"></img>
                </td>
                <td>o</td>
                <td>SSEDA,B</td>
              </tr>
            </table>
          </div>

          <div className="right">
            <span>
              <button className="submit__btn deactivate">
                DEACTIVATE DIAGRAM
              </button>
            </span>
            <span
              className="assign_co-ordinate"
              onClick={() => setHowToAssign(true)}
            >
              How to assign a coordinate to a part
            </span>

            <img alt="sample" src="./images/img.png" className="img"></img>
          </div>
        </div>
      </div>
    </>
  );
};
export default TableImg;
