import React from "react";
import FullWidthResponsiveTable from '../../components/GridViewFixedTable/FullWidthResponsiveTable'

const TableView = () => {
  const columns = [
    { title:'S No.', key:'s_no', class:'cm-8 text-center'},
    { title:'S No.', key:'s_no', class:'cm-8 text-center'},
    { title:'S No.', key:'s_no', class:'cm-8 text-center'},
    { title:'S No.', key:'s_no', class:'cm-8 text-center'},
    { title:'S No.', key:'s_no', class:'cm-8 text-center'},
    { title:'S No.', key:'s_no', class:'cm-8 text-center'},
    { title:'S No.', key:'s_no', class:'cm-8 text-center'},
    { title:'S No.', key:'s_no', class:'cm-8 text-center'},
    { title:'S No.', key:'s_no', class:'cm-8 text-center'},
    { title:'S No.', key:'s_no', class:'cm-8 text-center'},
    { title:'S No.', key:'s_no', class:'cm-8 text-center'},
    { title:'S No.', key:'s_no', class:'cm-8 text-center'},
    { title:'S No.', key:'s_no', class:'cm-8 text-center'},
    { title:'S No.', key:'s_no', class:'cm-8 text-center'},
  ]

  const dataList = [
      {s_no:1},
      {s_no:2},
      {s_no:3},
      {s_no:4},
      {s_no:5},
      {s_no:6},
      {s_no:7},
      {s_no:8},
    ]


  return (
    <div className="login">
      <FullWidthResponsiveTable
        fixedColumns={true} 
        columns = {columns} 
        data={dataList}  
     />
    </div>
  );
};

export default TableView;
