let baseURL = 'https://samsungpartsapi.mobikasa.net/api/v1/' 


export const serverDateFormat = (dt) => {
  if(dt){ var d = formatDate(dt,2); return d }
  else{ return dt; }
}

export const defaultDateFormat = (dt) => {
  if(dt){ var d = formatDate(dt,1); return d }
  else{ return dt; }
}

export const checkPermissions  = () => {
  return true;
}

export const getPriceFormat = (v) => {
  return v;
}
export const globalVariable =  {
    baseURL: baseURL,
    appVersion:'1.0'
}


export const timeSince = (date) => {
  var dt = new Date();
  dt.setMinutes( dt.getMinutes() - 330 );
  

  var seconds = Math.floor((dt - new Date(date)) / 1000);
  var interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

function formatDate(date,t) {
  if(date instanceof Date === false)
  {
    if(date.split('-').length > 1 || date.split('/').length > 1){
     // date = date+' 12:00:00';
    }
  }
  var d = new Date(date);
  d.setHours(12);
  var month = '' + (d.getUTCMonth() + 1),
      day = '' + d.getUTCDate(),
      year = d.getUTCFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;
  if(t===1){
      return [day,month,year].join('/');
  }else{
      return [year,month, day].join('-');   
  } 
}