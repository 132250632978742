import React, { useEffect } from "react";
import Loader from '../../components/Loader'
const Logout = (props) => {
    useEffect(()=>{
        localStorage.clear()
        setTimeout(()=>{
            props.history.replace('/')
        },500)
    },[props])
    return(
        <Loader show={true} />
    )
}

export default Logout