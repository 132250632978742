import React,{ useEffect,useRef,useState } from "react";
import Header from "../Common/Header";
import { GetData,checkLoggedIn, PutData } from '../../utils/apiHandler'
import Loader from '../../components/Loader'
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../../utils/constants';
import AddPartNo from "../../components/Modals/AddPartNo";
import AssignCoordinateInfo from "../../components/Modals/AssignCoordinateInfo";
import DeletePart from "../../components/Modals/DeletePart";
import EditSeo from "../../components/Modals/EditSeo";


let currentPage = 1
let limit = 20
let sortType = 'asc'
let _listData = []
let _partListData = []

const Diagram = (props) => {
    const diagramId = atob(props.match.params.id)
    const origin = window.location.origin
    const notificationRef = useRef();
    const [partPopupType,setPartPopupType] = useState('Add')
    const [loader,setLoader] = useState(false)
    const [editMode,setEditMode] = useState(false)
    const [list,setList] = useState([])
    const [partList,setPartList] = useState([])
    const [currentPart,setCurrentPart] = useState({})
    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddPartModal, setShowAddPartModal] = useState(false);
    const [howToAssign, setHowToAssign] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [diagramTitle,setDiagramTitle] = useState('--');
    const [diagramImage,setDiagramImage] = useState('')
    const [activeDiagram, setActiveDiagram]=useState('ACTIVATE DIAGRAM')
    const [seoData,setSEOData] = useState({seoTitle:"",seoDescription:""})
    const [coordinates,setCoordinates] = useState([])
    const [boxPosition,setBoxPosition] = useState({x:0,y:0})
    const [showSelector,setShowSelector] = useState(false)
    const [searchValue,setSearchValue] = useState('')
    const [filterText,setFilterTextData] = useState('')
    const [viewBox,setViewBox] = useState('');

    const closeEditModal = (getValue) => {
        setShowEditModal(getValue);
      };
    
      const closeAddPartModal = (getAddPart) => {
        setShowAddPartModal(getAddPart);
      };
    
      const closeHowToAssignModal = (getHowToAssign) => {
        setHowToAssign(getHowToAssign);
      };
    
      const closeDeleteModal = (getDeleteModal) => {
        setDeleteModal(getDeleteModal);
      };
    

    const getDiagrams = () => {
        setLoader(true)
        GetData('admin/diagrams/'+diagramId+'?page'+currentPage+'&limit='+limit).then((response)=>{
            setLoader(false)
            if(response.status === true){
                let listData = []
                let sno = ((currentPage-1)*limit)+1
                let coordinates = []
                response.data.part.map((data)=>{
                    data.sno = sno
                    data.rowStatus = data.description && data.specification ? 1 : 0
                    listData.push(data)
                    if(data.coordinates){
                        if(data.coordinates.x && data.coordinates.y){
                            coordinates.push({x:data.coordinates.x,y:data.coordinates.y,part:data})
                        }else{
                            delete data.coordinates
                        }
                    }
                    sno++;
                    return null
                })
                if(response.data.diagram[0].viewBox){
                    setViewBox(response.data.diagram[0].viewBox)
                }
                setCoordinates(coordinates)
                setSEOData({seoTitle:response.data.diagram[0].seoTitle,seoDescription:response.data.diagram[0].seoDescription})
                setDiagramTitle(response.data.diagram[0].diagramName+' - '+response.data.diagram[0].modelNumber)
                if(response.data.diagram[0].images){
                    setDiagramImage(response.data.baseUrl+response.data.diagram[0].images[0])
                }
                setList(listData)
                setPartList(listData)
                _listData = listData
                _partListData = listData
            }else{
                notificationRef.current.addNotification({
                    message: response.err_msg ? response.err_msg : 'Something went wrong. Please try again later or report to the administrator.',
                    level:  'error',
                  });
            }
        })
    }

    const editPart = (e,part) => {
        e.preventDefault()
        setPartPopupType('Edit')
        setCurrentPart(part)
        setShowAddPartModal(true)
    }

    const deletePart = (e,part) => {
        e.preventDefault()
        setCurrentPart(part)
        setDeleteModal(true)
    }
    const deactive = () => {
        if(activeDiagram === 'ACTIVATE DIAGRAM'){
            setActiveDiagram('DEACTIVATE DIAGRAM')
        }
        else{
                setActiveDiagram('ACTIVATE DIAGRAM')
        }
       
    }

    const editSEOhandler = (response) => {
        if(response.status === true){
            setShowEditModal(false);
            setSEOData({seoTitle:response.data.diagramData[0].seoTitle,seoDescription:response.data.diagramData[0].seoDescription})
            notificationRef.current.addNotification({
                message: "Title updated successfully",
                level:  'info',
              });
        }else{
            notificationRef.current.addNotification({
                message: response.msg ? response.msg : 'Server Error',
                level:  'error',
              });
        }
    }

    const sortTableData = () => {
        let tempList = [...list]
        let tempListNewData = []
        if(sortType === 'asc'){
            tempList.sort((a,b) => (a.rowStatus < b.rowStatus) ? 1 : ((b.rowStatus < a.rowStatus) ? -1 : 0))
            sortType = 'desc'
        }else{
            tempList.sort((a,b) => (a.rowStatus > b.rowStatus) ? 1 : ((b.rowStatus > a.rowStatus) ? -1 : 0))
            sortType = 'asc'
        }
        tempList.map((l,k)=>{
            l.sno = k+1
            tempListNewData.push(l);
            return null
        })
        setList(tempListNewData)
    }

    const searchParts = (e) => {
        if(e.target.value){
            let data = _listData.filter(x=> x.partNo.toLowerCase().search(e.target.value.toLowerCase()) !== -1 || x.description.toLowerCase().search(e.target.value.toLowerCase()) !== -1)
            let tmpData = []
            data.map((list,key)=>{
                list.sno = (key+1)
                tmpData.push(list)
                return null
            })
            setList(tmpData)
        }else{
            setList(_listData)
        }
    }
    function oMousePosSVG(e) {
        let svg = document.querySelector('svg')

        var p = svg.createSVGPoint();
        p.x = e.clientX;
        p.y = e.clientY;
        var ctm = svg.getScreenCTM().inverse();
        p =  p.matrixTransform(ctm);
        return p;
    }

    const setPointer = (e) =>{
        if(activeDiagram === 'ACTIVATE DIAGRAM'){
            alert('Please click on activate diagram to add coordinates')
        }else{
            let m = oMousePosSVG(e);
            let coordinatesTemp = [...coordinates]
            setCoordinatesData(e,coordinatesTemp.length,{x:m.x,y:m.y})
            coordinatesTemp.push({x:m.x,y:m.y})
            setCoordinates(coordinatesTemp)
        }
    }

    const setCoordinatesData = (e,key,coordinatesData="") => {
        e.preventDefault()
        if(activeDiagram !== 'ACTIVATE DIAGRAM'){ 
            if(coordinatesData){
                setBoxPosition({x:(coordinatesData.y)+'px',y:(coordinatesData.x)+'px'})
            }else{
                setBoxPosition({x:(coordinates[key].y)+'px',y:(coordinates[key].x)+'px'})
            }
            setSearchValue('')
            setPartList(_partListData)
            setShowSelector(key)
        }
    }

    const setPartNo = (key,partObject) => {
        const formData = {
            id:partObject.id,
            coordinates:JSON.stringify({"x":coordinates[key].x,"y":coordinates[key].y})
        }
        setLoader(true)
        PutData("admin/diagram/parts/"+partObject.id,formData).then((response)=>{
            setLoader(false)
            if(response.status === true){
                notificationRef.current.addNotification({
                    message: "Part added successfully",
                    level:  'info',
                  });
                let coordinatesTemp = [...coordinates]
                coordinatesTemp[key].part = partObject
                setCoordinates(coordinatesTemp)

                let listTemp = [...list]
                const listIndex = listTemp.findIndex(x=>x.id===partObject.id)
                listTemp[listIndex]['coordinates'] = {x:coordinates[key].x,y:coordinates[key].y}
                setList(listTemp)
                setSearchValue('')
                setPartList(_partListData)
                setShowSelector(false)
            }else{
                notificationRef.current.addNotification({
                    message: response.msg,
                    level:  'error',
                  });
            }
        })   
    }

    const removePartCoordinates = (partObject) => {
        const formData = {
            id:partObject.id,
            coordinates:JSON.stringify({"x":"","y":""})
        }
        setLoader(true)
        PutData("admin/diagram/parts/"+partObject.id,formData).then((response)=>{
            setLoader(false)
            if(response.status === true){
                let coordinatesTemp = [...coordinates]
                coordinatesTemp.splice(showSelector,1)
                console.log('coordinatesTemp',coordinatesTemp)
                setCoordinates(coordinatesTemp)
                /*let listTemp = [...list]
                const listIndex = listTemp.findIndex(x=>x.id===partObject.id)
                listTemp[listIndex]['coordinates'] = false
                setList(listTemp)
                setShowSelector(false)
                setSearchValue('')
                setPartList(_partListData)*/
                notificationRef.current.addNotification({
                    message: "Part deleted successfully",
                    level:  'info',
                  });
            }else{
                notificationRef.current.addNotification({
                    message: response.msg ? response.msg : 'Something went wrong. Please try again later or report to the administrator.',
                    level:  'error',
                  });
            }
        })
        
    }

    useEffect(()=>{
        if(!checkLoggedIn()){
          props.history.push('/')
        }else{
            getDiagrams()
        }
    },[props])

    return (
        <>
        <Loader show={loader} />
        <Header showMenu={true} activeMenu = "diagrams"/>
        {/* Edit seo modal */}
            <EditSeo
                seoData = {seoData}
                setLoader = {setLoader}
                editSEOhandler={editSEOhandler}
                openEditseoModalProp={showEditModal}
                callbackEditSeo={closeEditModal}
                diagramId={diagramId}
            />
            {/* Add part number */}
            <AddPartNo
                currentPart={currentPart}
                partPopupType = {partPopupType}
                getDiagrams = {getDiagrams}
                notificationRef = {notificationRef}
                setLoader = {setLoader}
                diagramId={diagramId}
                openAddPartModalProp={showAddPartModal}
                callbackAddPart={closeAddPartModal}
            />
            {/* How to assign Modal */}
            <AssignCoordinateInfo
                openHowToAssignModalProp={howToAssign}
                callbackHowToAssign={closeHowToAssignModal}
            />

            {/* Delete Modal */}
            <DeletePart
                getDiagrams = {getDiagrams}
                currentPart={currentPart}
                notificationRef = {notificationRef}
                setLoader = {setLoader}
                openDeleteModalProp={deleteModal}
                callbackDelete={closeDeleteModal}
            />

            <div className='upper'>
                <div className="back" onClick={()=>props.history.push('/diagram-list')}> 
                    <img src={origin+'/images/back.svg'} alt="back" /> Back
                </div>
                <div className='row'>
                    <div className='refrigerator diagram-title'>
                        {diagramTitle}
                    </div>
                    {
                        editMode === false ? 
                        <> 
                        <div className='parallel'>
                            {/*<img src={origin+'/images/close.svg'} className='close-img' onClick={()=>{setEditMode(false)}}></img>*/}
                            <div className="btn-1">
                                    <button className="edit-btn" id='main-edit-btn' onClick={() => setShowEditModal(true)}>
                                    EDIT SEO
                                    </button>
                                </div>
                            <div className="btn-1">
                                <button
                                    className="add-btn" id='main-add-btn'
                                    onClick={() => { setPartPopupType('Add'); setCurrentPart({}); setShowAddPartModal(true)}}
                                >
                                ADD PART
                                </button>
                            </div>
                        </div>
                        </>
                        :
                        <div className='btn-1'>
                            <button className="edit-main-btnn " onClick={() => setEditMode(true)}>
                                EDIT
                            </button>
                        </div>
                    }


                </div>
                <div className='input-main' style={{display:'flex'}}>
                    <div>
                        <p style={{fontSize:'14px',marginRight:'15px'}}>Search</p>
                    </div>
                    <div>
                        <input type='text' value={filterText} onChange={(e)=>{ setFilterTextData(e.target.value); searchParts(e)}} className="text__box table_input" placeholder='Enter Keywords'></input>
                        <img className='icon-img' alt="search" src={origin+'/images/search.svg'}></img>
                        {
                            filterText.length ?
                                <a href="/#" onClick={(e)=>{e.preventDefault();searchParts({target:{value:""}}); setFilterTextData('');}} style={{position:'absolute',top:'13px',right:'0'}}>
                                    <img src={origin+"/images/cancel.svg"} alt="reset" />
                                </a>
                            :
                            ""
                        }
                    </div>
                </div>


            </div>
            <div className='table' style={{paddingBottom:'70px'}}>
                <div className='Part_table'>
                    <div className='left'>

                        <table className="tableLayout custom">
                            <thead className="left-table-heading">
                            <tr>                     
                                <th style={{width:'30px'}}>No.</th>
                                <th>Part Number</th>
                                <th>Description</th>
                                <th style={{width:'80px'}}>Action</th>
                                <th onClick={()=>sortTableData()} style={{cursor:'pointer',width:'80px'}}>Status 
                                    <img alt="sort" src={origin+'/images/sort.svg'}></img>
                                </th>
                                <th>Specs</th>
                            </tr>
                            </thead>
                            <tbody className='tablebody'>
                            {
                                list.length > 0 ?
                                    list.map((diagram,key)=>{
                                        return(
                                            <tr key={key}>
                                                <td style={{width:'30px'}}>{diagram.no}</td>
                                                <td className='diagram-partno'>{diagram.partNo}</td>
                                                <td>{diagram.description ? diagram.description : '--'}</td>
                                                <td style={{width:'80px'}}>
                                                    <a href="/#" style={{marginRight:'10px'}} onClick={(e)=>editPart(e,diagram)} id='td-edit'><img alt="edit" src={origin+'/images/edit.svg'} /></a>
                                                    <a href="/#" onClick={(e)=>deletePart(e,diagram)} ><img alt="delete" src={origin+'/images/delete.svg'} className='del_img'/></a>
                                                </td>
                                                <td style={{width:'80px'}}>
                                                    <div className="statusIcons">
                                                        {
                                                            diagram.coordinates ? 
                                                                "" 
                                                            :
                                                                <div style={{width:'auto'}}><img className='noun-img' src={origin+'/images/no-coordinates.svg'} alt="coordinates" /></div>
                                                        }
                                                        
                                                        {
                                                            diagram.description && diagram.specification ? 
                                                                <div className="icon active"></div>
                                                            :
                                                                <div className="icon inActive"></div>
                                                        }

                                                    </div>
                                                </td>
                                                <td>{diagram.specification ? diagram.specification : '--'}</td>
                                            </tr>
                                        )
                                    })
                                :
                                <tr>
                                    <td colSpan="6">Data not available...</td>
                                </tr>
                            }
                            </tbody>
                        </table>                        
                    </div>

                    <div className='right'>
                        <span className="btn-container">
                            <button className='submit__btn deactivate' id='deactive' onClick={deactive}>{activeDiagram}</button>
                        </span>
                        <span
                        className='assign_co-ordinate'
                        onClick={()=> setHowToAssign(true)}
                        >
                            How to assign a coordinate to a part
                        </span>
                        {
                            diagramImage ? 
                            <div style={{border: '1px solid #f2f2f2',width:'100%',position:'relative'}}>
                                <svg style={{zIndex:1}} onDoubleClick={(e)=>setPointer(e)} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox={viewBox ? viewBox : "0 0 453.3 630.1"}>
                                    <image x="0" y="0" width={viewBox ? viewBox.split(" ")[2] : ""} style={viewBox ? {} : {width:'100%'}} xlinkHref={diagramImage} />
                                    {
                                        coordinates.length ? 
                                            coordinates.map((c,k)=>{
                                                return(
                                                    <g onClick={(e)=>setCoordinatesData(e,k)} key={k} id="DA97-06137C" className="highlight" style={{fill: "black",cursor:'pointer',zIndex:2}}>
                                                        <circle id="DA97-06137C-Circle" cx={c.x} cy={c.y} r="6" className="panzoom-exclude tooltip-dot tooltipstered" data-tooltip-content="#tooltip_content_da97-06137c"></circle>
                                                    </g>
                                                )
                                            })
                                        :
                                        ""
                                    }
                                </svg>
                                {
                                    showSelector !== false && coordinates.length ?
                                        <div className="svgBox" style={{top:boxPosition.x,left:boxPosition.y,width:'225px'}}>
                                            {
                                                coordinates.length ?
                                                    <div onClick={()=>{ 
                                                            if(coordinates[showSelector]?.part){
                                                                setSearchValue('')
                                                                setPartList(_partListData)
                                                                setShowSelector(false)
                                                            }else{
                                                                let coordinatesTemp = [...coordinates]
                                                                coordinatesTemp.splice(showSelector,1)
                                                                setCoordinates(coordinatesTemp)
                                                                setSearchValue('')
                                                                setPartList(_partListData)
                                                                setShowSelector(false)
                                                            }
                                                        }
                                                    } className="closeIcon">
                                                        <img src={origin+'/images/close-popup.svg'} alt="close"/></div>
                                                :
                                                ""
                                            }
                                            <div className="searchPart">
                                                <img src={origin+'/images/search-dark.svg'} alt="back" /> 
                                                <input type="text" placeholder="Search" onChange={(e)=>{
                                                    if(e.target.value){
                                                        let data = _partListData.filter(x=> x.partNo.search(e.target.value) !== -1 || x.description.search(e.target.value) !== -1)
                                                        let tmpData = []
                                                        data.map((list,key)=>{
                                                            list.sno = (key+1)
                                                            tmpData.push(list)
                                                            return null
                                                        })
                                                        setPartList(tmpData)
                                                    }else{
                                                        setPartList(_partListData)
                                                    }
                                                    setSearchValue(e.target.value)
                                                }} className="searchInput" value={searchValue}/>
                                            </div>
                                            {
                                                coordinates.length ? 
                                                    coordinates[showSelector]?.part ?
                                                        <span className="selectedPart">
                                                            <span className="part">{coordinates[showSelector].part.partNo}</span>
                                                            <span onClick={()=>{
                                                                removePartCoordinates(coordinates[showSelector].part)
                                                            }} className="removePart">Remove</span>
                                                        </span>
                                                    :
                                                    ""
                                                :
                                                ""
                                            }
                                            
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th style={{width:'40px'}}>No.</th>
                                                        <th>Part Number</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="left">
                                                    {
                                                        partList.length ? 
                                                            partList.map((diagram,key)=>{
                                                                return(
                                                                    <tr key={key}>
                                                                        <td style={{width:'40px'}}>{diagram.no}</td>
                                                                        <td onClick={()=>setPartNo(showSelector,diagram)} className="blue">{diagram.partNo}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        :
                                                        <tr key={0}>
                                                            <td colSpan="2">Data not available..</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    :
                                    ""
                                }
                            </div>
                            :
                            <div style={{
                                        border: '1px solid #f2f2f2', 
                                        width:'100%',
                                        minHeight: '400px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                }}>Image not available</div>
                        }
                    </div>
                </div>
            </div>
            <NotificationSystem
            dismissible={false}
            ref={notificationRef}
            style={NOTIFICATION_SYSTEM_STYLE}
          />
        </>
    );
};
export default Diagram;