import React,{ useState,useRef } from "react";
import { Link } from "react-router-dom";
import { PostWithoutToken } from '../../utils/apiHandler'
import Loader from '../../components/Loader'
import Header from "../Common/Header";
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../../utils/constants';

const ResetPassword = (props) => {
  const notificationRef = useRef();
  const [loader,showLoader] = useState(false)

  const [password,setPassword] = useState('')
  const [confirmPassword,setConfirmPassword] = useState('')

  const [passwordError,setPasswordError] = useState('')
  const [confirmPasswordError,setConfirmPasswordError] = useState('')

  const resetPassword = () => {
    let error = 0
    if(password === ''){
      error = 1
      setPasswordError("Please enter password")
    }
    if(confirmPassword === ''){
      error = 1
      setConfirmPasswordError("Please enter password again")
    }

    if(error === 0 && confirmPassword !== password){
      error = 1
      setConfirmPasswordError("Confirm password does not match with password")
    }

    if(!error){
      showLoader(true)
      const query = new URLSearchParams(props.location.search);
      const resetToken = query.get('token');
      const formData = {
        reset_token:resetToken,
        new_password:password,
        new_password_confirm:confirmPassword
      }
      PostWithoutToken('admin/reset-password',formData).then((response)=>{
        showLoader(false)
        
        if(response.status === true){
          notificationRef.current.addNotification({
            message: response.msg,
            level:  'info',
          });
          setTimeout(()=>{ props.history.push('/') },1000)
        }else{
          notificationRef.current.addNotification({
            message: response.msg ? response.msg : 'Server Error',
            level:  'error',
          });
        }
      })
    }
  }
  return (
    <>
      <Header showMenu={false}/>
      <div className="login">
        <Loader show={loader} />
        <div className="login__wrap">
          <h2>Reset Password</h2>
          <div className="login__form">
            <form>
              <div className="input__wrap">
                <p>New Password</p>
                <input 
                type="password" 
                autoComplete={false}
                value={password} 
                placeholder="**********" 
                className="text__box login-input" 
                onChange={(e)=>{
                  if(e.target.value === ''){
                    setPasswordError("Please enter password")
                  }else{
                    setPasswordError("")
                  }
                  setPassword(e.target.value)
                }} />
                <div className="user_span" id="span">
                  {passwordError}
                </div>
              </div>
              <div className="input__wrap">
                <p>Confirm New Password</p>
                <input 
                type="password" 
                value={confirmPassword} 
                placeholder="**********" 
                className="text__box login-input" 
                onChange={(e)=>{
                  if(e.target.value === ''){
                    setConfirmPasswordError("Please enter password again")
                  }else{
                    setConfirmPasswordError("")
                  }
                  setConfirmPassword(e.target.value)
                }} />
                <div className="user_span" id="span">
                  {confirmPasswordError}
                </div>
              </div>
              <div className="btn__wrap">
                <button
                  onClick={()=>resetPassword()}
                  type="button"
                  className="submit__btn"
                >
                  Reset Password
                </button>
                <Link to="/" className="forget__btn">
                  Back
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <NotificationSystem
            dismissible={false}
            ref={notificationRef}
            style={NOTIFICATION_SYSTEM_STYLE}
          />
    </>
  );
};

export default ResetPassword;
