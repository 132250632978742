import React, { useState, useRef, useEffect } from "react";
import FullWidthResponsiveTable from "../../components/GridViewFixedTable/FullWidthResponsiveTable";
import Header from "../Common/Header";
import { GetData, checkLoggedIn } from "../../utils/apiHandler";
import Loader from "../../components/Loader";
import NotificationSystem from "react-notification-system";
import { NOTIFICATION_SYSTEM_STYLE } from "../../utils/constants";
import DeleteDiagram from "../../components/Modals/DeleteDiagram";
import {CSVLink} from "react-csv";

let currentPage = 1;
let limit = 10;
let filterBy = 'diagramName'
let timeOutRef = ''
let filterTextValue = ''
let sortBy = ''
let sortOrder = ''
let sortTypeData = {
  diagramName:"NONE",
  modelNumber:"NONE",
  seoTitle:"NONE",
  seoDescription:"NONE"
}
const csvColumns = [
  { Header: 'Diagram Name', accessor: 'diagramName' },
  { Header: 'Model Number', accessor: 'modelNumber' },
  { Header: 'SEO Title', accessor: 'seoTitle' },
  { Header: 'SEO Description', accessor: 'seoDescription' }
]

const csvColumnsInternal = [
  { Header: 'No.', accessor: 'no' },
  { Header: 'Part Number', accessor: 'partNo' },
  { Header: 'Description', accessor: 'description' },
  { Header: 'Specs', accessor: 'specification' },
  { Header: 'Coordinates(X)', accessor: 'coordinatesX' },
  { Header: 'Coordinates(Y)', accessor: 'coordinatesY' },
]

const DiagramList = (props) => {
  const notificationRef = useRef();
  const csvRef = useRef();
  const [loader, setLoader] = useState(false);
  const [currentId,setCurrentId] = useState(0)
  const [list, setList] = useState([]);
  const [deleteModal,setDeleteModal] = useState(false)
  const [filterText,setFilterText] = useState('')
  const [pagination,setPagination] = useState({currentPage: 1,recordPerPage: 10,totalRecord: 10})
  const [sortTypes,setSortTypes] = useState()
  const [selectedRowIds,setSelectedRowIds] = useState([])
  const [dataToDownload,setDataToDownload] = useState([])

  useEffect(() => {
    if(!checkLoggedIn()){
      props.history.push('/')
    }else{
      setSortTypes(sortTypeData)
      getDiagrams();
    }
  }, [props]);

  const columns = [
    { title: "S.No.", key: "sno", class: "cm-8 text-center" },
    { title: "Diagram Name",action:true, key: "diagramName", class: "cm-12 text-center" },
    { title: "Model Number.", key: "modelNumber", class: "cm-12 text-center" },
    { title: "SEO Title", key: "seoTitle", class: "cm-12 text-center" },
    { title: "SEO Description", key: "seoDescription", class: "cm-12 text-center"},
    { title: "Status", key: "status",type:'status', class: "cm-12 text-center" },
    { title: "Action", key: "action", class: "cm-10 text-center" },
    { title: "", key: "donwload",type:"download", class: "cm-12 text-center" }
  ];

  const handleActions = (type, id) => {
    switch (type) {
      case "delete":
        console.log('id',id)
        setCurrentId(id);
        setDeleteModal(true)
        break;
      case "view":
        props.history.push("/diagram-list/view/" + btoa(id));
        break;
      default:
        break;
    }
  };

  const getDiagrams = () => {
    setLoader(true);
    let actions = [
      { key: "view", title: "View" },
      { key: "delete", title: "Delete" },
    ];
    let query = "admin/diagrams?page=" + currentPage + "&limit=" + limit
    

    if(filterTextValue){ query+='&'+filterBy+'='+filterTextValue }
    if(sortBy && sortOrder){
      query+="&sortBy="+sortBy+"&sortOrder="+sortOrder
    }
    GetData(query).then(
      (response) => {
        setLoader(false);
        if (response.status === true) {
          let listData = [];
          let sno = (currentPage - 1) * limit + 1;
          response.data.data.map((data) => {
            data.sno = sno;
            data.status = 'false';
            if(data.seoTitle && data.seoDescription){
              data.status = 'true';
            }
            data.action = actions;
            listData.push(data);
            sno++;
            return null;
          });
          setPagination({ currentPage: currentPage,
                          recordPerPage: limit,
                          totalRecord: response.data.count
                        });
          setList(response.data.data);
        } else {
          notificationRef.current.addNotification({
            message: response?.msg ? response.msg : 'Something went wrong. Please try again later or report to the administrator.',
            level: "error",
          });
        }
      }
    );
  };

  const closeDeleteModal = (t,response) => {
    setDeleteModal(false)
    if(response){
      notificationRef.current.addNotification({
        message: response.msg ? typeof response.msg === 'string' ? response.msg : 'Something went wrong. Please try again later or report to the administrator.' : 'Something went wrong. Please try again later or report to the administrator.',
        level:  response.status ? 'info' : 'error',
      })
    }
  }

  const setLimit = (v) => {
    limit = v;
    currentPage = 1
    getDiagrams();
  }

  /*const setFilterBy = (v) => {
    filterBy = v;
    if(filterTextValue){
      getDiagrams()
    }
  }*/

  const setFilterTextData = (v) => {
    setFilterText(v)
    if(timeOutRef){
      clearTimeout(timeOutRef)
    }
    timeOutRef = setTimeout(()=>{
      filterTextValue = v
      getDiagrams();
    },2000)
  }

  const paginationHandler = (page) => {
    currentPage = page
    getDiagrams()
  }

  const sortHandler = (e,key,type) => {
    e.preventDefault()
    let sortType = ""
    if(type==='NONE'){ sortType = "ASC"}
    if(type==='ASC'){ sortType = "DESC" }
    if(type==='DESC'){ sortType = "ASC" }

    let sortObject = sortTypeData
    
    for(let k in sortObject){ sortObject[k] = 'NONE' }
    sortObject[key] = sortType
    setSortTypes(sortTypeData)
    
    sortOrder = sortType
    sortBy = key
    getDiagrams()
  }

  const selectRowHandler = (e,id) => {
    let selectedRows = [...selectedRowIds]
    if(selectedRows.includes(id)){
      let ind = selectedRows.indexOf(id)
      selectedRows.splice(ind,1)
    }else{
      selectedRows.push(id)
    }
    setSelectedRowIds(selectedRows)
  }

  const downloadAll = () => {
    let url = "admin/diagrams/download"
    if(selectedRowIds.length){
      url+="?ids="+selectedRowIds.join(',')
      setSelectedRowIds([])
    }
    exportCsv(url,'all')
  }

  const download = (e,k) => {
    e.preventDefault()
    const url = "admin/diagrams/download?ids="+list[k].id
    exportCsv(url,'one')
  }

  const exportCsv = (url,type) => {
    setLoader(true)
    GetData(url).then((response)=>{
      setLoader(false)
      if(response.status === true){
        
        let data = response.data.diagramPartsData
        let csvColumnsData = csvColumnsInternal
        if(type === 'all'){ 
          data = response.data.diagramData
          csvColumnsData = csvColumns
        }
        
        var data_to_download = []
          for (var index = 0; index < data.length; index++) {
            let record_to_download = {sno:(index+1)}
            for(var colIndex = 0; colIndex < csvColumnsData.length ; colIndex ++) {
              if(csvColumnsData[colIndex].accessor === 'coordinatesX' || csvColumnsData[colIndex].accessor === 'coordinatesY'){
                  if(csvColumnsData[colIndex].accessor === 'coordinatesX'){
                    if(data[index]['coordinates']){
                      record_to_download[csvColumnsData[colIndex].Header] = data[index]['coordinates'].x ? data[index]['coordinates'].x : '--'
                    }else{
                      record_to_download[csvColumnsData[colIndex].Header] = '--'
                    }
                  }
                  if(csvColumnsData[colIndex].accessor === 'coordinatesY'){
                    if(data[index]['coordinates']){
                      record_to_download[csvColumnsData[colIndex].Header] = data[index]['coordinates'].y ? data[index]['coordinates'].y : '--'
                    }else{
                      record_to_download[csvColumnsData[colIndex].Header] = '--'
                    }
                  }
              }else{
                  record_to_download[csvColumnsData[colIndex].Header] = data[index][csvColumnsData[colIndex].accessor]
              }
            }
            
            data_to_download.push(record_to_download)
          }
          setDataToDownload(data_to_download)
          
          csvRef.current.link.click()
      }else{
        notificationRef.current.addNotification({
          message: response.err_msg ? response.err_msg : 'Something went wrong. Please try again later or report to the administrator.',
          level: 'error',
        })
      }
    })
  }

  return (
    <>
      <Loader show={loader} />
      <Header showMenu={true} activeMenu="diagrams" />
      {/* Delete Modal */}
      <DeleteDiagram
          currentId = {currentId}
          getDiagrams={getDiagrams}
          notificationRef = {notificationRef}
          setLoader = {setLoader}
          openDeleteModalProp={deleteModal}
          callbackDelete={closeDeleteModal}
      />
      <div className="upper ">
        <div className="roww"> 
          <div className="refrigerator">Diagrams</div>
          <div className="edit-btnn" onClick={()=>downloadAll()}>Download All</div>
        </div>
      </div>

      <div className="filter__search--container">
        
        <div className="show__limits">
          <p>Show</p>
          <select onChange={(e)=>setLimit(e.target.value)}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </select>
          <span className='entries'>results</span>
        </div>
        <div className="search__table" style={{display:'flex'}}>
          <p style={{fontSize:'14px',marginRight:'15px'}}>Search</p>
          <div className="search__box">
            <img src="./images/search.svg" className="searchIcon" alt="search" />
            <input
              value={filterText}
              onChange={(e)=>setFilterTextData(e.target.value)}
              type="text"
              placeholder="Enter Keywords"
              className="text__box no-border pl-35"
            />
            {
              filterText.length ?
                <a href="/#" onClick={(e)=>{e.preventDefault(); setFilterTextData('');}} className="closeIcon"><img src="./images/cancel.svg" alt="reset" /></a>
              :
              ""
            }
          </div>
        </div>
        <div className="filter__limits mr-auto">
        {/*  <p>Filter by</p>
          <select onChange={(e)=>setFilterBy(e.target.value)}>
            <option value="diagramName">Diagram Name</option>
            <option value="modelNumber">Model Number</option>
          </select>*/}
        </div>
        
      </div>

      <div className="table">
        <FullWidthResponsiveTable
          selectRowHandler = {selectRowHandler}
          selectedRowIds={selectedRowIds}
          fixedColumns={true}
          columns={columns}
          data={list}
          handleActions={handleActions}
          paginationHandler={paginationHandler} 
          showPagination="true" 
          pagination={pagination}
          sortHandler={sortHandler}
          sortTypes={sortTypes}
          download={download}
        />
      </div>
      <NotificationSystem
        dismissible={false}
        ref={notificationRef}
        style={NOTIFICATION_SYSTEM_STYLE}
      />
      <CSVLink
        data={dataToDownload}
        filename="data.csv"
        className="hidden"
        ref={csvRef}
        target="_blank"/>
    </>
  );
};

export default DiagramList;
