import React, { useState,useEffect } from "react";
import Modal from "react-modal";
import { PostData, PutData } from "../../utils/apiHandler";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "50%",
    bottom: "auto ",
    marginRight: "-40.5%",
    transform: "translate(-50%, -50%)",
    transition: "all 1s ease-in",
  },
};
Modal.setAppElement("#root");
let _update = false
const AddPartNo = (props) => {
  // close modal
  const [formData,setFormData] = useState({partNo:"",no:"",specification:"",description:"",seoDescription:"",seoTitle:""})
  const [formDataError,setFormDataError] = useState({})
  
  useEffect(()=>{
    if(props.currentPart.id){
      if(_update === false){
        setFormData({id:props.currentPart.id,materialCode:props.currentPart.materialCode,partNo:props.currentPart.partNo,no:props.currentPart.no,specification:props.currentPart.specification,description:props.currentPart.description,seoDescription:"",seoTitle:""})
      }
    }else{
      setFormData({partNo:"",no:"",specification:"",description:"",seoDescription:"",seoTitle:""})
    }
  },[props])

  const closeAddPartModal = () => {
    props.callbackAddPart(false);
  };

  const addPart = () => {
    setFormDataError({})
    let error = 0
    if(formData.partNo === ''){
      error = 1
      setFormDataError(prev => ({...prev, partNo: "Please enter part number"}))
    }
    if(formData.no === ''){
      error = 1
      setFormDataError(prev => ({...prev, no: "Please enter number"}))
    }
    if(formData.description === ''){
      error = 1
      setFormDataError(prev => ({...prev, description: "Please enter description"}))
    }
    if(formData.specification === ''){
      error = 1
      setFormDataError(prev => ({...prev, specification: "Please enter specification"}))
    }
    if(!error){
      props.setLoader(true)
      formData.diagramId = props.diagramId
      if(props.currentPart.id)
      {
        _update = true
        PutData('admin/diagram/parts/'+props.currentPart.id,formData).then((response)=>{
          props.setLoader(false)
          if(response.err_msg){
            props.notificationRef.current.addNotification({
              message: response.err_msg,
              level:  'error',
            });
          }else{
            setFormData({partNo:"",no:"",specification:"",description:"",seoDescription:"",seoTitle:""})
            props.callbackAddPart(false);
            props.notificationRef.current.addNotification({
              message: 'Part updated successfully',
              level:  'info',
            });
            _update = false
            props.getDiagrams()
          }
        })
      }
      else
      {
        _update = true
        PostData('admin/diagram/parts',formData).then((response)=>{
          props.setLoader(false)
          if(response.err_msg){
            props.notificationRef.current.addNotification({
              message: response.err_msg,
              level:  'error',
            });
          }else{
            
            setFormData({partNo:"",no:"",specification:"",description:"",seoDescription:"",seoTitle:""})
            props.callbackAddPart(false);
            props.notificationRef.current.addNotification({
              message: 'Part added successfully',
              level:  'info',
            });
            props.getDiagrams()
            _update = false
          }
        })
      }
    }
  }

  return (
    <div className="edit-seo-container">
      <Modal
        isOpen={props.openAddPartModalProp}
        onRequestClose={() => props.callbackAddPart(false)}
        shouldCloseOnOverlayClick={() => props.callbackAddPart(false)}
        style={customStyles}
        animation={true}
        animationType="slide"
        backdrop="static"
      >
        <div className="editseo__container">
          <div className="close-icon">
            <img
              src={window.location.origin+"/images/cancel.svg"}
              alt="close"
              onClick={closeAddPartModal}
            />
          </div>

          <div className="editseo__wrap">
            <h2 className="add-part-h2">{props.partPopupType === 'Add' ? 'Add a new part' : 'Update a part'}</h2>
            <div className="editseo__form">
              <form>
                <div className="editseo__form__div">
                  <p className="font-italic">*All fields are required.</p>
                </div>
                <div className="editseo__form__div">
                  <p className='modals-lable'>Part Number</p>
                  <div className='w-100'>
                  <input 
                    type="text" 
                    onChange={(e)=>{
                      setFormDataError(prev => ({...prev, partNo: ''}))
                      setFormData(prev => ({...prev, partNo: e.target.value}))
                    }} 
                    disabled={props.partPopupType !== 'Add' ? true : false}
                    value={formData.partNo ? formData.partNo : ""} 
                    className="text__box" />
                    <div className="user_span" id="span">
                      {formDataError.partNo ? formDataError.partNo : ""}
                    </div>
                    </div>
                </div>
                <div className="editseo__form__div">
                  <p>Number</p>
                  <div className='w-100'>
                  <input 
                    type="text" 
                    //disabled={props.partPopupType !== 'Add' ? true : false}
                    onChange={(e)=>{
                      setFormDataError(prev => ({...prev, no: ''}))
                      setFormData(prev => ({...prev, no: e.target.value}))
                    }} 
                    value={formData.no ? formData.no : ""} 
                    className="text__box" />
                    <div className="user_span" id="span">
                      {formDataError.no ? formDataError.no : ""}
                    </div>
                    </div>
                </div>
                <div className="editseo__form__div">
                  <p>Description</p>
                  <div className='w-100'>
                  <input 
                    type="text" 
                    onChange={(e)=>{
                      setFormDataError(prev => ({...prev, description: ''}))
                      setFormData(prev => ({...prev, description: e.target.value}))
                    }} 
                    value={formData.description ? formData.description : ""} 
                    className="text__box" />
                    <div className="user_span" id="span">
                      {formDataError.description ? formDataError.description : ""}
                    </div>
                    </div>
                </div>
                <div className="editseo__form__div">
                  <p>Specification</p>
                  <div className='w-100'>
                  <input 
                    type="text" 
                    onChange={(e)=>{
                      setFormDataError(prev => ({...prev, specification: ''}))
                      setFormData(prev => ({...prev, specification: e.target.value}))
                    }} 
                    value={formData.specification ? formData.specification : ""} 
                    className="text__box" />
                    <div className="user_span" id="span">
                      {formDataError.specification ? formDataError.specification : ""}
                    </div>
                    </div>
                </div>
                <div>
                  {/* <p className="error-msg mb-0 font-italic">
                    Please fill in the missing fields.
                  </p> */}
                </div>
                <div className="editseo__form__div editseo__flexrow">
                  <button type="reset" onClick={closeAddPartModal} className="submit__btn bg--dark">
                    CANCEL
                  </button>
                  <button type="button" onClick={addPart} className="submit__btn">
                    SAVE
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddPartNo;
