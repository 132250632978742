import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
          selectedPaging:localStorage.getItem('pagingNum') ? localStorage.getItem('pagingNum') : 10
        }
    }
    selectPaging = (e,num) => {
        e.preventDefault();
        this.props.paginationHandler('',num)
        localStorage.setItem('pagingNum',num)
        this.setState({selectedPaging:num})
      }
    render() {
        let totalPage = Math.ceil(this.props.pagination.totalRecord / (this.props.pagination.recordPerPage));
        let activePage = this.props.pagination.currentPage;
        let items = [];
        let paginationStart = 1;
        let paginationStop = totalPage < 5 ? totalPage : 5;
        let stop, number;
        if (totalPage > 5 && activePage > 4) {
            paginationStart = activePage - 3;
            stop = activePage + 1;
            paginationStop = stop < totalPage ? stop : totalPage;
        }

        for (number = paginationStart; number <= paginationStop; number++) {
            if(paginationStart === 2 && number === paginationStart){
                items.push(1);
            }
            if(paginationStart >= 3 && number === paginationStart){
                items.push(1);
                items.push('...');
            }
            items.push(number);
            if(totalPage > 5 && number === 5 && activePage < 5){
                items.push('...');
                items.push(totalPage);
            }
            if(activePage >= 5 && totalPage > 5 && number === activePage+1){
                if(activePage < totalPage - 2){
                    items.push('...');
                }
                if(activePage < totalPage - 1){
                    items.push(totalPage);
                }
            }
        }
        if(items.length === 8){
            items.splice(2,1)
            items[1] = '...'
        }

        if(items.length === 9){
            items.splice(2,1)
            items.splice(3,1)
        }
        
        
        return (
            
            <div className="tablePagination" style={this.props.paddingBottom === 'none' ? {paddingBottom:0}:{}}>
                <Row>
                    <Col className="pagination-text" sm="auto">
                        <span className="lightText">Showing</span>  {' '} {(this.props.pagination.recordPerPage * this.props.pagination.currentPage) - (this.props.pagination.recordPerPage - 1)} {' '} <span className="lightText">to</span> {' '}
                        {
                            Math.ceil(this.props.pagination.totalRecord / this.props.pagination.recordPerPage) === this.props.pagination.currentPage ?
                                (((this.props.pagination.currentPage - 1) * this.props.pagination.recordPerPage) + this.props.length) : (this.props.pagination.recordPerPage * this.props.pagination.currentPage)
                        }{' '}<span className="lightText"> of{' '} {this.props.pagination.totalRecord} elements</span>
                    </Col>
                    <Col style={{display:'flex',alignItems:'center'}}>
                    <span className="pageText">Page</span>
                        <ul className="pagination">
                            {
                                items.length > 1 && items.map((ele, index) => {
                                    return (
                                        <li key={index} className={"page-item " + (ele === this.props.pagination.currentPage ? 'active' : 'not')}>
                                            {
                                            this.props.checkpage ?
                                                <button className="page-link 1" onClick={(event) => { document.getElementById(this.props.tableId).scrollTo({top: 0,left:0, behavior: 'smooth'}); ele === '...' ? console.log('none') : this.props.paginationHandler(ele, this.props.search ?  this.props.search : '',this.props.orderBy ? this.props.orderBy : '',this.props.sortByKey ? this.props.sortByKey : '') }}>{ele}</button>
                                                :
                                                <button className="page-link 2" onClick={(event) => { document.getElementById(this.props.tableId).scrollTo({top: 0,left:0, behavior: 'smooth'}); ele === '...' ? console.log('none') : this.props.paginationHandler(ele, this.props.search ?  this.props.search : '',this.props.orderBy ? this.props.orderBy : '',this.props.sortByKey ? this.props.sortByKey : '') }}>{ele}</button>
                                            }
                                        </li>
                                    )
                                })
                            }
                           
                        </ul>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Pagination;